import { usePostV1UserBalanceReplenishmentMutation } from 'api/api';
import { IStrapiOrganisationAttributes } from 'fsd/entities/strapi/api/types';

import createInvoicePDF from './createInvoicePDF';

type CreateInvoiceArg = {
  amount: string;
  organisation: Pick<
    IStrapiOrganisationAttributes,
    'organisationName' | 'organisationType' | 'inn' | 'kpp' | 'organisationID' | 'createdAt'
  >;
};

type PDF = ReturnType<typeof createInvoicePDF>;

export type CreateInvoiceResult = {
  amount: string;
  pdf?: PDF;
  organisation: CreateInvoiceArg['organisation'];
};

export default () => {
  const [amount, setAmount] = useState('');
  const [organisation, setOrganisation] = useState<CreateInvoiceResult['organisation'] | null>(
    null
  );
  const [pdf, setPdf] = useState<PDF | null>(null);
  const [userBalanceReplenishment, { isLoading }] = usePostV1UserBalanceReplenishmentMutation();

  const create = useCallback(
    async ({
      amount,
      organisation
    }: CreateInvoiceArg): Promise<CreateInvoiceResult | undefined> => {
      if (!organisation || !amount) return undefined;

      try {
        await userBalanceReplenishment({
          body: {
            amount
          }
        }).unwrap();
        const pdf = createInvoicePDF({
          amount,
          org: {
            id: organisation.organisationID,
            createdAt: organisation.createdAt,
            type: organisation.organisationType,
            name: organisation.organisationName,
            inn: organisation.inn,
            kpp: organisation.kpp
          }
        });
        setPdf(pdf);
        setAmount(amount);
        setOrganisation(organisation);
        return {
          amount,
          pdf,
          organisation
        };
      } catch (error) {
        return undefined;
      }
    },
    [userBalanceReplenishment]
  );

  return [create, { isLoading, amount, pdf, organisation }] as [
    typeof create,
    CreateInvoiceResult & { isLoading: typeof isLoading }
  ];
};
