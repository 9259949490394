import { NavLink } from 'react-router-dom';
import { getCartContainerIdSelector } from 'fsd/entities/cart/model/selectors';
import { EditContainerTitleInline } from 'fsd/features/editContainerTitle';
import { GoToCatalogWithCart } from 'fsd/features/goToCatalogWithCart';
import { IconCart, IconFolderEdit } from 'fsd/shared/icons';
import { useIsMobile } from 'fsd/shared/lib/hooks/useIsMobile';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';
import { Button } from 'fsd/shared/ui/Buttons';
import { HeaderMobileWidget, HeaderWidget } from 'fsd/widgets/Header';

import { ContainerPageContext } from '../../lib/pageContext';

import * as S from './styles';

const Header = () => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const pageData = useContext(ContainerPageContext);
  const isMobile = useIsMobile();
  return (
    pageData?.container && (
      <HeaderWidget
        caption="Кампания"
        icon={<IconFolderEdit />}
        titleSize="medium"
        title={
          <EditContainerTitleInline
            maxWidth={isMobile ? 'calc(100vw - 32px - 28px)' : '50vw'}
            container={pageData.container}
          />
        }
      >
        {!cartContainerId && !isMobile && (
          <GoToCatalogWithCart containerId={pageData.container.container_id} />
        )}
      </HeaderWidget>
    )
  );
};

export const HeaderMobile = memo(() => {
  const cartContainerId = useAppSelector(getCartContainerIdSelector);
  const pageData = useContext(ContainerPageContext);
  const isMiniToCart = useMediaQuery('(max-width: 380px)');
  return (
    pageData?.container && (
      <HeaderMobileWidget>
        <S.HeaderContentWrapper>
          <NavLink to="/campaigns">
            <Button startIcon={<IconCart />} size="middle" square type="primary" />
          </NavLink>
          {!cartContainerId && (
            <GoToCatalogWithCart
              containerId={pageData.container.container_id}
              mini={isMiniToCart}
            />
          )}
        </S.HeaderContentWrapper>
      </HeaderMobileWidget>
    )
  );
});

export default memo(Header);
