/* eslint-disable no-nested-ternary */
import { FC, MouseEventHandler, useEffect, useRef, useState } from 'react';
import { FormSpy } from 'react-final-form';
import { useSearchParams } from 'react-router-dom';
import { useLazyChannelsQuery } from 'api/api';
import { IconCircle, IconEarth, IconReach, IconSubscribers, IconViews } from 'fsd/shared/icons';
import { fixData, flattenObj } from 'fsd/shared/lib/helpers/tools';
import { Icon } from 'fsd/shared/ui/Icon';
import { LoaderSpinner } from 'fsd/shared/ui/LoaderSpinner';
import sign from 'jwt-encode';
import { useDebouncedCallback } from 'use-debounce';

import { theme } from 'theme/theme';

// types
import { FormValues } from '../../types';
import { useCatalogLogic } from '../Catalog/hooks/useCatalogLogic';

import ChannelTypeFilter from './components/ChannelTypeFilter';
import CPMFilter from './components/CPMFilter';
import ErrFilter from './components/ErrFilter';
import FormatTypeFilter from './components/FormatTypeFilter';
import IsVerifiedFilter from './components/IsVerifiedFilter';
import { LanguageFilter } from './components/LanguageFilter';
import ParticipantsFilter from './components/ParticipantsFilter';
import PriceFilter from './components/PriceFilter';
import ReachFilter from './components/ReachFilter';

import * as S from './styles';
import { isDev } from 'fsd/shared/lib/featureFlags';

type Props = Pick<
  ReturnType<typeof useCatalogLogic>,
  'values' | 'form' | 'handleSubmit' | 'resetFilters' | 'filtersCount'
>;

const Filters: FC<Props> = ({ values, form, handleSubmit, filtersCount, resetFilters }) => {
  const { now } = useDate();
  const [, setSearchParams] = useSearchParams();
  const [getChannels, { isLoading, isError, data }] = useLazyChannelsQuery();

  const { total = 0 } = data || {};
  const [isLoadingorDataChanged, setIsLoadingorDataChanged] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const [widthButton, setWidthButton] = useState(0);
  const [prevValues, setPrevValues] = useState<FormValues | null>(null);

  const refetchChannels = useDebouncedCallback(
    useCallback(() => {
      if (values !== prevValues) {
        setPrevValues(values);
        setIsLoadingorDataChanged(true);
        getChannels({
          filter: sign(
            fixData({ ...values, pagination: { offset: 0, limit: 0 } }, now),
            import.meta.env.VITE_REACT_APP_JWT_SECRET || ''
          )
        }).then(() => {
          setIsLoadingorDataChanged(false);
        });
      }
    }, [getChannels, now, prevValues, values]),
    100
  );

  const clearPagination = () => {
    setSearchParams(
      flattenObj({
        ...values,
        pagination: {
          offset: 0,
          limit: 20
        }
      })
    );
    form?.change('pagination', {
      offset: 0,
      limit: 20
    });
    form?.submit();
  };

  const handleResetClick: MouseEventHandler<HTMLButtonElement> = useCallback(
    (e) => {
      e.preventDefault();
      resetFilters();
    },
    [resetFilters]
  );

  useEffect(() => {
    if (!values.is_exchange_active) {
      form?.change('is_exchange_active', true);
      form?.submit();
    }
  }, [form, values.is_exchange_active]);

  useEffect(() => {
    if (isLoading) setIsLoadingorDataChanged(true);
  }, [isLoading]);

  useEffect(() => {
    if (buttonRef.current && !isLoadingorDataChanged) {
      setWidthButton(buttonRef.current.offsetWidth);
    }
  }, [isLoadingorDataChanged, buttonRef]);

  return (
    <S.Form
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit(values);
      }}
    >
      <FormSpy onChange={refetchChannels} />
      <S.PageTitle>
        <S.StyledFilterIcon />
        <span>Фильтры</span>
      </S.PageTitle>

      <S.MobileSearchResults>
        <S.Chosen>
          <span>Выбрано: </span>
          <span>{filtersCount}</span>
        </S.Chosen>

        <div>
          <S.MobileClearBtn type="text" htmlType="reset" onClick={handleResetClick}>
            СБРОСИТЬ
          </S.MobileClearBtn>
        </div>
      </S.MobileSearchResults>

      <S.Row>
        <div>
          <S.Label>
            <IconSubscribers />
            <span>Подписчики</span>
          </S.Label>

          <ParticipantsFilter />
        </div>
        {/* <div>
          <S.Label>
            <Icon width={18} color={theme.persianBlue}>
              <IconViews />
            </Icon>

            <span>Просмотры на пост</span>
          </S.Label>

          <ViewsFilter />
        </div> */}

        <div>
          <S.Label>
            <Icon width={18} color={theme.persianBlue}>
              <IconReach />
            </Icon>

            <span>Средний охват</span>
          </S.Label>

          <ReachFilter />
        </div>
      </S.Row>
      <S.Divider />

      {/* <S.Row>
        <div>
          <S.Label>
            <IconSpeaker />
            <span>Упоминания</span>
          </S.Label>

          <MentionsFilter />
        </div>
        <div>
          <S.Label>
            <Icon width={18} color={theme.persianBlue}>
              <IconViews />
            </Icon>
            <span>ER</span>
          </S.Label>

          <ErFilter />
        </div>
      </S.Row>

      <S.Divider /> */}

      <S.Row>
        <div>
          <S.Label>
            <Icon width={18} color={theme.persianBlue}>
              <IconCircle />
            </Icon>

            <span>Стоимость</span>
          </S.Label>
          <PriceFilter />
        </div>

        <div>
          <S.Label>
            <Icon width={18} color={theme.persianBlue}>
              <IconViews />
            </Icon>

            <span>ERR</span>
          </S.Label>

          <ErrFilter />
        </div>
      </S.Row>
      <S.Divider />

      <S.Row>
        <div>
          <S.Label>
            <span>Формат</span>
          </S.Label>
          <FormatTypeFilter />
        </div>

        <div>
          <S.Label>
            <span>CPM</span>
          </S.Label>
          <CPMFilter />
        </div>
      </S.Row>
      <S.Divider />

      {/* <S.Row>
        <div>
          <S.Label>
            <IconEarth />
            <span>Язык</span>
          </S.Label>

          <LanguageFilter form={form} values={values} />
        </div>
        <div>
          <S.Label>
            <IconLocation />
            <span>Страна</span>
          </S.Label>

          <CountryFilter form={form} values={values} />
        </div>
      </S.Row>

      <S.Divider /> */}

      <S.Row>
        <div>
          <S.Label htmlFor="is_public">
            <span>Тип канала</span>
          </S.Label>

          <ChannelTypeFilter />
        </div>

        <div>
          <S.Label>
            <span>Верификация</span>
          </S.Label>

          <IsVerifiedFilter />
        </div>
      </S.Row>

      <S.Footer>
        <S.ResetBtn type="link" htmlType="reset" onClick={handleResetClick}>
          Сбросить все
        </S.ResetBtn>

        {!isError ? (
          <S.SubmitBtn
            disabled={!total}
            ref={buttonRef}
            type="primary"
            htmlType={isLoadingorDataChanged ? 'button' : 'submit'}
            isLoadingorDataChanged={isLoadingorDataChanged}
            widthButton={widthButton}
            size="middle"
            onClick={clearPagination}
          >
            {isLoadingorDataChanged ? (
              <S.LoaderWrapper>
                <LoaderSpinner />
                Поиск...
              </S.LoaderWrapper>
            ) : !total ? (
              'Нет результатов'
            ) : (
              `Показать ${total} каналов`
            )}
          </S.SubmitBtn>
        ) : (
          'Ошибка'
        )}
      </S.Footer>
    </S.Form>
  );
};

export default Filters;
