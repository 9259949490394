import { MouseEventHandler } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  useDeleteV1UserOrganisationByOrganisationIdMutation,
  useGetV1OrganisationTypesQuery,
  useGetV1UserQuery
} from 'api/api';
import { editOrganisationSchema, OrganisationForm } from 'fsd/entities/organisation';
import { getUserOrganisationId, useGetUserOrganisation } from 'fsd/entities/user';
import { ButtonVariant } from 'fsd/shared/ui/Buttons';
import Loader from 'fsd/shared/ui/Loader';
import { type z } from 'zod';

import * as S from './styles';

const Form = () => {
  const { data: organisationData, isLoading: organisationDataLoading } = useGetUserOrganisation();
  const { data: user } = useGetV1UserQuery();
  const { data: organisationTypes } = useGetV1OrganisationTypesQuery();
  const [deleteOrganisation, { isLoading }] = useDeleteV1UserOrganisationByOrganisationIdMutation();

  const userOrganisationId = user && getUserOrganisationId(user)?.toString();
  const organisationAttributes = organisationData?.data?.attributes;

  const handleFinish = useCallback<SubmitHandler<z.infer<typeof editOrganisationSchema>>>(() => {
    if (userOrganisationId) {
      console.log('error edit');
    }
  }, [userOrganisationId]);

  const handleRemove = useCallback<MouseEventHandler<HTMLButtonElement>>(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (userOrganisationId) {
        deleteOrganisation({
          organisationId: userOrganisationId
        });
      }
    },
    [deleteOrganisation, userOrganisationId]
  );

  const initialValues = useMemo<Partial<z.infer<typeof editOrganisationSchema>>>(() => {
    const isAdvertiser = Boolean(organisationAttributes?.internalContractAdvertiser);
    const isAdmin = Boolean(organisationAttributes?.internalContractAdmin);
    return {
      // eslint-disable-next-line no-nested-ternary
      contragentType: isAdvertiser && isAdmin ? 3 : isAdmin ? 1 : isAdvertiser ? 2 : 1,
      inn: organisationAttributes?.inn,
      kpp: organisationAttributes?.kpp,
      ogrn: organisationAttributes?.ogrn,
      organisation_name: organisationAttributes?.organisationName,
      organisation_type: organisationTypes?.find(
        (type) => type.name === organisationAttributes?.organisationType
      )?.id,
      owner_full_name: organisationAttributes?.fullName
    };
  }, [
    organisationAttributes?.fullName,
    organisationAttributes?.inn,
    organisationAttributes?.internalContractAdmin,
    organisationAttributes?.internalContractAdvertiser,
    organisationAttributes?.kpp,
    organisationAttributes?.ogrn,
    organisationAttributes?.organisationName,
    organisationAttributes?.organisationType,
    organisationTypes
  ]);

  const { control, handleSubmit, setValue, watch } = useForm<
    z.infer<typeof editOrganisationSchema>
  >({
    defaultValues: initialValues,
    resolver: zodResolver(editOrganisationSchema)
  });

  const organisationType = watch('organisation_type');

  useEffect(() => {
    Object.entries(initialValues).forEach(([key, value]) =>
      setValue(key as keyof typeof initialValues, value)
    );
  }, [initialValues, setValue]);

  return (
    <OrganisationForm.Root onFinish={handleSubmit(handleFinish)} disabled>
      {organisationDataLoading ? (
        <S.LoaderWrapper>
          <Loader />
        </S.LoaderWrapper>
      ) : (
        <>
          <OrganisationForm.Fields
            control={control}
            organisationType={organisationType}
            organisationTypes={organisationTypes ?? []}
          />
          <OrganisationForm.Footer>
            <OrganisationForm.Buttons>
              <OrganisationForm.Submit
                disabled={isLoading || !userOrganisationId}
                variant={ButtonVariant.TETRIARY_RED}
                htmlType="button"
                onClick={handleRemove}
              >
                Удалить организацию
              </OrganisationForm.Submit>
            </OrganisationForm.Buttons>
          </OrganisationForm.Footer>
        </>
      )}
    </OrganisationForm.Root>
  );
};

export default memo(Form);
