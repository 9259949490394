import { ComponentProps, FC } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetV1OrganisationTypesQuery, usePostV1UserOrganisationMutation } from 'api/api';
import { createOrganisationSchema, OrganisationForm } from 'fsd/entities/organisation';
import { URLs } from 'fsd/shared/config/URLs';
import { isDev } from 'fsd/shared/lib/featureFlags';
import { type z } from 'zod';

import DevRandom from './DevRandom';

type Props = {
  onSuccess?: () => void;
  allowLater?: boolean;
  onLater?: () => void;
};

const Form: FC<Props> = ({ onSuccess, allowLater, onLater }) => {
  const { data: organisationTypes } = useGetV1OrganisationTypesQuery();
  const [create, { isLoading }] = usePostV1UserOrganisationMutation();

  const handleFinish = useCallback<SubmitHandler<z.infer<typeof createOrganisationSchema>>>(
    (data) => {
      create({
        body: {
          ...data,
          kpp: data.kpp ?? '',
          is_admin: [1, 3].includes(data.contragentType),
          is_advertiser: [2, 3].includes(data.contragentType)
        }
      })
        .unwrap()
        .then(() => onSuccess?.());
    },
    [create, onSuccess]
  );

  const {
    control,
    handleSubmit,
    formState: { isValid, isSubmitted },
    setValue,
    clearErrors,
    watch
  } = useForm<z.infer<typeof createOrganisationSchema>>({
    defaultValues: {
      contragentType: 2,
      organisation_type: organisationTypes?.[0].id ?? 1
    },
    resolver: zodResolver(createOrganisationSchema)
  });

  const handleRandomFinish = useCallback<ComponentProps<typeof DevRandom>['onFinish']>(
    (data) => {
      if (isDev() && data) {
        Object.entries(data).forEach(([key, value]) => {
          setValue(key as keyof typeof data, value, { shouldDirty: true, shouldValidate: true });
        });
        setTimeout(() => clearErrors(), 0);
      }
    },
    [setValue, clearErrors]
  );

  const organisationType = watch('organisation_type');

  return (
    <>
      <OrganisationForm.Root onFinish={handleSubmit(handleFinish)} disabled={isLoading}>
        <OrganisationForm.Fields
          control={control}
          organisationType={organisationType}
          organisationTypes={organisationTypes ?? []}
        />
        <OrganisationForm.Footer>
          <OrganisationForm.Info>
            Внимательно проверьте данные! <br />
            После подтверждения изменить их сможет только служба поддержки Tgmarket.
          </OrganisationForm.Info>
          <OrganisationForm.AcceptOffer control={control}>
            Я прочитал(а) и соглашаюсь с агентским{' '}
            <a href={URLs.OFFERS} target="_blank" rel="noreferrer">
              договором-офертой
            </a>
          </OrganisationForm.AcceptOffer>
          <OrganisationForm.Buttons>
            {allowLater && (
              <OrganisationForm.Later onClick={onLater}>Заполнить позже</OrganisationForm.Later>
            )}
            <OrganisationForm.Submit disabled={(isSubmitted && !isValid) || isLoading}>
              Сохранить
            </OrganisationForm.Submit>
          </OrganisationForm.Buttons>
        </OrganisationForm.Footer>
      </OrganisationForm.Root>
      <DevRandom onFinish={handleRandomFinish} />
    </>
  );
};

export default memo(Form);
