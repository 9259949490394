import { FC, PropsWithChildren } from 'react';
import { type CheckboxProps } from 'antd';

import * as S from './Checkbox.styles';

type Props = {
  error?: boolean;
} & CheckboxProps;

const Checkbox: FC<PropsWithChildren<Props>> = ({ children, error, ...restProps }) => {
  return (
    <S.Root $isError={error} {...restProps}>
      {children}
    </S.Root>
  );
};

export default Checkbox;
