import { ComponentProps } from 'react';
import { IconArrowSquire } from 'fsd/shared/icons';
import { Button as SharedButton } from 'fsd/shared/ui/Buttons';

import { WithdrawalBalanceForm } from '../Form';

import Modal from './Modal';

const Button = (props: ComponentProps<typeof SharedButton>) => {
  const [openModal, setOpenModal] = useState(false);

  const handleSuccess = () => {
    setOpenModal(false);
  };

  return (
    <>
      <Modal open={openModal} onCancel={() => setOpenModal(false)}>
        <WithdrawalBalanceForm onSuccess={handleSuccess} />
      </Modal>
      <SharedButton
        {...props}
        onClick={() => setOpenModal((val) => !val)}
        type="outlined"
        size="middle"
        endIcon={<IconArrowSquire />}
      >
        Вывести
      </SharedButton>
    </>
  );
};

export default Button;
