import styled from 'styled-components';

export const Root = styled.label`
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  font-weight: inherit;
  border: none;
  position: relative;
`;

export const Pseudo = styled.span`
  font-size: inherit;
  font-weight: inherit;
`;

export const Input = styled.input`
  display: none;
  color: inherit;
  font-size: inherit;
  cursor: pointer;
  font-weight: inherit;
  border: none;
  outline: none;
  background: none;
  &::placeholder {
    color: currentColor !important;
    font-size: inherit !important;
    font-weight: inherit !important;
  }
  &:disabled {
    background: none !important;
  }
  .ant-picker-input-placeholder & {
    color: ${({ theme }) => theme.bayFog};
  }
`;
