import React, { FunctionComponent, type ReactNode } from 'react';
import { Button, ButtonProps } from 'antd';
import styled, { css } from 'styled-components';

import { Icon } from '../Icon';

export enum ButtonVariant {
  ORANGE = 'orange',
  TETRIARY = 'tetriary',
  DARK_TETRIARY = 'dark_tetriary',
  TETRIARY_RED = 'tetriary_red',
  WHITE = 'white',
  OUTLINED = 'outlined'
}

type AdditionalTypes = 'outlined' | 'positive' | 'glass' | 'secondary';

interface CustomButtonProps {
  variant?: ButtonVariant;
  iconSize?: number;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  type?: ButtonProps['type'] | AdditionalTypes;
  square?: boolean;
}

type ButtonPropsCustom = Omit<ButtonProps, 'type'> & CustomButtonProps;

const StyledButton = styled(Button as FunctionComponent<ButtonPropsCustom>)<ButtonPropsCustom>`
  --height: 36px;
  height: var(--height);
  border-radius: 6px;
  text-shadow: none;
  display: flex;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  align-items: center;
  justify-content: center;
  gap: 12px;
  color: ${({ type, theme }) => type === 'primary' && theme.maWhite};
  a& {
    padding-bottom: 0 !important;
    padding-top: 0 !important;
    line-height: 100% !important;
  }

  &:hover {
    background-color: ${({ type, theme }) => type === 'primary' && theme.primaryBtnHover};
    border-color: ${({ type, theme }) => type === 'primary' && theme.primaryBtnHover};
  }

  ${(props) =>
    (props.type === 'default' || props.type === 'secondary') &&
    css`
      background: ${props.theme.sweetFlower};
      border-color: ${props.theme.sweetFlower};

      color: ${props.theme.blackRock};

      &:hover {
        background: #f2f3f7 !important;
        border-color: #f2f3f7 !important;
        color: ${props.theme.blackRock} !important;
      }
    `}

  ${(props) =>
    // @ts-ignore
    props.type === 'outlined' &&
    css`
      background: none;
      border-color: ${props.theme.persianBlue};
      color: ${props.theme.persianBlue};
      &:hover {
        color: ${props.theme.maWhite};
        background: ${props.theme.primaryBtnHover};
        border-color: ${props.theme.primaryBtnHover};
      }
    `}

  ${(props) =>
    props.type === 'default' &&
    props.disabled &&
    css`
      background: #656575 !important;
      border-color: #656575 !important;
      opacity: 0.25;

      color: ${props.theme.white} !important;

      &:hover {
        background: #656575 !important;
        border-color: #656575 !important;
        opacity: 0.25;
        color: ${props.theme.white} !important;
      }
    `}

  ${(props) =>
    props.type === 'primary' &&
    css`
      border-color: ${theme.persianBlue} !important;
      background: ${theme.persianBlue} !important;

      &:hover {
        background-color: ${theme.primaryBtnHover} !important;
      }

      :disabled {
        background: ${theme.persianBlue} !important;
        border-color: ${theme.persianBlue} !important;
      }
    `}

  ${(props) =>
    props.type === 'ghost' &&
    css`
      border: none !important;
      background: transparent !important;
      color: ${props.theme.persianBlue};

      &:hover {
        border: none !important;
        background-color: ${props.theme.alpineGoat} !important;
      }
      &:focus {
        border: none !important;
      }

      :disabled {
        background: transparent !important;
        border: none !important;
      }
    `}

  ${(props) =>
    props.variant === ButtonVariant.ORANGE &&
    css`
      background-color: ${props.theme.mangoTango};
      border-color: ${props.theme.mangoTango};

      &:hover {
        background-color: ${props.theme.orangeBtnHover};
        border-color: ${props.theme.orangeBtnHover};
      }

      :disabled {
        background-color: ${props.theme.mangoTango} !important;
        border-color: ${props.theme.mangoTango} !important;
        opacity: 0.25;
      }
    `}

  ${(props) =>
    props.variant === ButtonVariant.TETRIARY &&
    css`
      border-color: ${props.theme.persianBlue} !important;
      background-color: ${props.theme.white} !important;
      color: ${props.theme.persianBlue} !important;

      &:hover {
        background-color: ${props.theme.persianBlue} !important;
        color: ${props.theme.white} !important;
      }
    `}

    ${(props) =>
    props.variant === ButtonVariant.OUTLINED &&
    css`
      border-color: ${props.theme.white} !important;
      background-color: transparent !important;
      color: ${props.theme.white} !important;
      opacity: 1;

      &:hover {
        background-color: rgba(255, 255, 255, 0.3) !important;
        color: ${props.theme.white} !important;
      }
    `}

  ${(props) =>
    props.variant === ButtonVariant.DARK_TETRIARY &&
    css`
      border-color: ${props.theme.white} !important;
      background-color: rgba(0, 0, 0, 0) !important;
      color: ${props.theme.maWhite} !important;

      &:hover {
        border-color: ${props.theme.indivioletSunset} !important;
        background-color: rgba(0, 0, 0, 0) !important;
      }
    `}

  ${(props) =>
    props.variant === ButtonVariant.TETRIARY_RED &&
    css`
      border-color: ${props.theme.strawberryRed} !important;
      background-color: ${props.theme.white} !important;
      color: ${props.theme.strawberryRed} !important;

      &:hover {
        background-color: ${props.theme.strawberryRed} !important;
        color: ${props.theme.white} !important;
      }
    `}


  ${(props) =>
    props.variant === ButtonVariant.WHITE &&
    css`
      border: none;
      box-shadow: none;
      background: ${props.theme.white} !important;

      color: ${props.theme.darkGray} !important;

      &:hover {
        color: ${props.theme.persianBlue} !important;
      }
    `}


  ${(props) =>
    props.type === 'primary' &&
    props.disabled &&
    css`
      background: ${props.theme.brightGreek} !important;
      color: ${props.theme.white} !important;
      opacity: 0.25;
    `}
  ${(props) =>
    props.type === 'ghost' &&
    props.disabled &&
    css`
      background: ${props.theme.blackGrey} !important;
      color: ${props.theme.white} !important;
      opacity: 0.25;
    `}

  ${(props) =>
    props.type === 'positive' &&
    css`
      background: ${props.theme.americanGreen} !important;
      color: ${props.theme.white} !important;
      border-color: ${props.theme.americanGreen} !important;
      ${() =>
        !props.disabled
          ? css`
              &:hover {
                background: ${props.theme.pastelGreen} !important;
                border-color: ${props.theme.pastelGreen} !important;
                color: ${props.theme.white} !important;
              }
            `
          : css`
              background: ${props.theme.pastelGreen} !important;
              border-color: ${props.theme.pastelGreen} !important;
            `}
      &:focus {
        border-color: ${props.theme.pastelGreen} !important;
      }
    `}

  ${(props) =>
    props.type === 'glass' &&
    css`
      --antd-wave-shadow-color: ${props.theme.paisley};
      background: transparent !important;
      color: ${props.theme.paisley} !important;
      border-color: transparent !important;
      box-shadow: none !important;
      ${() =>
        !props.disabled
          ? css`
              &:hover {
                background: transparent !important;
                border-color: transparent !important;
                color: ${props.theme.blackRock} !important;
              }
            `
          : css`
              color: ${props.theme.bayFog} !important;
            `}
      &:focus {
        border-color: transparent !important;
      }
    `}

  ${(props) =>
    props.square &&
    css`
      width: var(--height) !important;
      padding: 0 !important;
      gap: 0;
      flex: none;
    `}

  ${(props) =>
    props.size === 'large' &&
    css`
      --height: 48px;
      gap: 12px;
    `}

  ${(props) =>
    props.size === 'middle' &&
    css`
      --height: 36px;
      gap: 12px;
    `}

  ${(props) =>
    props.size === 'small' &&
    css`
      --height: 32px;
      padding: 6px 20px;
      gap: 8px;
    `}
`;

const ButtonEndIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonStartIcon = styled(Icon)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ButtonUI = forwardRef<HTMLButtonElement, ButtonPropsCustom>(
  ({ children, endIcon, startIcon, iconSize = 18, ...props }, ref) => (
    // @ts-ignore
    <StyledButton ref={ref} {...props}>
      {startIcon && (
        <ButtonStartIcon width={iconSize} color="currentColor">
          {startIcon}
        </ButtonStartIcon>
      )}
      {children}
      {endIcon && (
        <ButtonEndIcon width={iconSize} color="currentColor">
          {endIcon}
        </ButtonEndIcon>
      )}
    </StyledButton>
  )
);

export default memo(ButtonUI);

export const ButtonSmall = styled(ButtonUI)`
  font-size: 14px;
  font-size: 14px;
  font-weight: 600;
  line-height: 142.857%;
`;
