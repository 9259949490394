import { Form } from 'antd';
import { Button } from 'fsd/shared/ui/Buttons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';
import styled from 'styled-components';

export const Root: typeof Form = styled(Form)`
  display: flex;
  flex-direction: column;
`;

export const PresetWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
  margin-top: 16px;
`;

export const Preset = styled(OutlinedButton)`
  flex: 1;
  min-width: min-content;
`;

export const Caption = styled.span`
  font-size: 12px;
  font-weight: 400;
  margin-top: 10px;
  text-align: center;
`;

export const CaptionAccent = styled.strong`
  font-size: inherit;
  font-weight: inherit;
  color: ${({ theme }) => theme.persianBlue};
`;

export const SubmitButton = styled(Button).attrs({ block: true, type: 'primary', size: 'large' })`
  margin-top: 24px;
`;
