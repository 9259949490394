import { type FC } from 'react';
import { useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { zodResolver } from '@hookform/resolvers/zod';
import { useGetV1UserQuery, usePostV1UserBalanceWithdrawalMutation } from 'api/api';
import { strapiApi, useGetOrganisationsQuery } from 'fsd/entities/strapi';
import { getUserOrganisationId } from 'fsd/entities/user';
import { getCurrencyIcon } from 'fsd/shared/lib/helpers/tools';
import { Button } from 'fsd/shared/ui/Buttons';
import { InputNumber } from 'fsd/shared/ui/Input';
import { ToastMessage } from 'fsd/shared/ui/Toast';
import CustomToast from 'fsd/shared/ui/Toast/customToast';

import schema from '../model/schema';

import * as S from './styles';

type Props = {
  onSuccess?: () => void;
};

const Form: FC<Props> = ({ onSuccess }) => {
  const dispatch = useAppDispatch();
  const [userBalanceWithdrawal, { isLoading }] = usePostV1UserBalanceWithdrawalMutation();

  const { data: user } = useGetV1UserQuery();
  const organisationId = user && getUserOrganisationId(user, 'crm');
  const { data: client } = useGetOrganisationsQuery(
    {
      // TODO : Надо понять что делать в таких случаях при skip
      // @ts-ignore
      id: organisationId
    },
    {
      skip: !organisationId
    }
  );

  const clientData = client?.data;

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      amount: ''
    },
    resolver: zodResolver(
      schema({
        max: clientData?.attributes?.profitBalance ?? Number.MAX_SAFE_INTEGER
      })
    ),
    disabled: isLoading
  });

  const handleFinish = useCallback<Parameters<typeof handleSubmit>[0]>(
    (data) => {
      userBalanceWithdrawal({
        body: data
      })
        .unwrap()
        .then(() => {
          CustomToast({
            message: <ToastMessage title="Заявка на вывод средств отправлена менеджеру" />,
            type: 'success'
          });
          dispatch(strapiApi.util.invalidateTags(['StrapiBalance']));
          reset();
          onSuccess?.();
        })
        .catch(() => {
          CustomToast({
            message: <ToastMessage title="Не удалось отправить заявку на вывод средств" />,
            type: 'error'
          });
        });
    },
    [dispatch, onSuccess, reset, userBalanceWithdrawal]
  );

  return (
    <S.Root onFinish={handleSubmit(handleFinish)} disabled={isLoading}>
      <FormItem
        control={control}
        name="amount"
        getValueFromEvent={({ target }) => Number(target.value).toString()}
      >
        <InputNumber placeholder="0" postfix={` ${getCurrencyIcon().toString()}`} min={0} />
      </FormItem>
      <Button htmlType="submit" type="primary" disabled={isLoading}>
        Отправить заявку
      </Button>
    </S.Root>
  );
};

export default memo(Form);
