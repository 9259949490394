import { FC, PropsWithChildren } from 'react';

import { IMessageData } from '../types';
import ChatMessage from '..';

import * as S from './styles';

type ItemProps = {
  media?: IMessageData['media'];
};

const Item: FC<PropsWithChildren<ItemProps>> = ({ children, media }) => {
  console.log(media);
  return (
    <S.Root>
      {!!media?.length && (
        <S.Album>
          {media.map((mediaItem) => (
            <ChatMessage.Media key={mediaItem.src} media={mediaItem} />
          ))}
        </S.Album>
      )}
      <S.TextContainer>
        <ChatMessage.Content>{children}</ChatMessage.Content>
      </S.TextContainer>
      <S.MessageTail
        src="data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTEiIGhlaWdodD0iMTQiIHZpZXdCb3g9IjAgMCAxMSAxNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTQuNSAwQzMuNSA2IDEuNSAxMi45NjYyIDAgMTMuOTY2MkMyLjMzMzMzIDE0LjEzMjkgNy44IDEzLjc2NjIgMTEgMTAuOTY2Mkw0LjUgMFoiIGZpbGw9IndoaXRlIi8+Cjwvc3ZnPgo="
        alt="tail"
      />
    </S.Root>
  );
};

export { Item };
