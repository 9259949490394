/* eslint-disable no-unused-vars */
import { ComponentProps, FC } from 'react';
import { PurchaseRead, useGetV1ExchangePurchasesContainersByContainerIdQuery } from 'api/api';
import { BalanceInvoiceInfo, BalanceRecharge, useCreateBalanceInvoice } from 'fsd/entities/balance';
import { getPurchasesPrice } from 'fsd/entities/purchase';
import { useGetUserOrganisation } from 'fsd/entities/user';

type Props = {
  onSuccess?: () => void;
};

type FormProps = ComponentProps<typeof BalanceRecharge.Form>;

const Form: FC<Props> = ({ onSuccess }) => {
  const { data: userOrganisation } = useGetUserOrganisation();
  const [step, setStep] = useState<'createInvoice' | 'invoiceCreated'>('createInvoice');
  const [createInvoice, invoiceData] = useCreateBalanceInvoice();

  const handleFinish = useCallback<FormProps['onFinish']>(
    (data) => {
      if (userOrganisation?.data?.attributes) {
        createInvoice({
          amount: data.amount,
          organisation: userOrganisation.data.attributes
        }).then(() => {
          setStep('invoiceCreated');
        });
      }
    },
    [createInvoice, userOrganisation?.data?.attributes]
  );
  const presets: FormProps['presets'] = [50000, 200000].map((val) => ({
    label: `${val.toLocaleString('ru')} ₽`,
    value: val.toString()
  }));

  return step === 'createInvoice' ? (
    <BalanceRecharge.Form
      onFinish={handleFinish}
      button="Сгенерировать счет на оплату"
      loading={invoiceData.isLoading ? { button: 'Генерируем' } : undefined}
      defaultValue="100000"
      presets={presets}
    />
  ) : (
    <BalanceInvoiceInfo invoice={invoiceData} />
  );
};

export default memo(Form);
