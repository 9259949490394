import { useEffect, useMemo, useState } from 'react';
import { Checkbox } from 'antd';
import {
  Category,
  useGetV1CategoriesQuery,
  useGetV1UserCategoryPinnedQuery,
  usePostV1UserCategoryPinMutation
} from 'api/api';
import { IconPin } from 'fsd/shared/icons';
import { useStickyState } from 'fsd/shared/lib/hooks/useStickyState';

import * as S from './styles';

type SubjectListProps = {
  onCheckedCategoriesChange: (nextSelectedCategories: Array<number>) => void;
  initiallySelectedCategories?: number[];
};

function SubjectList({
  onCheckedCategoriesChange,
  initiallySelectedCategories = []
}: SubjectListProps) {
  const [searchStr, setSearchStr] = useState('');
  const [pinned, setPinned] = useStickyState([], 'pinnedCategories');
  const [selectedCategories, setSelectedCategories] = useState<number[]>(
    initiallySelectedCategories
  );
  const { data: categories } = useGetV1CategoriesQuery();
  const { data: pinnedCategoriesData, refetch: refetchPinnedCategories } =
    useGetV1UserCategoryPinnedQuery();
  const [pinCategory] = usePostV1UserCategoryPinMutation();
  const [areCategoriesRefetched, setAreCategoriesRefetched] = useState(false);

  useEffect(() => {
    refetchPinnedCategories().then(() => setAreCategoriesRefetched(true));
  }, []);

  console.log(categories);

  const pinnedCategories = useMemo(
    () => pinnedCategoriesData?.category_list,
    [pinnedCategoriesData]
  );

  useEffect(() => {
    if (pinnedCategories && areCategoriesRefetched) {
      setPinned(pinnedCategories);
    }
  }, [pinnedCategories, areCategoriesRefetched]);

  useEffect(() => {
    setSelectedCategories(initiallySelectedCategories.map((i) => Number(i)));
  }, [String(initiallySelectedCategories)]);

  const toggleCategoryPin = (categoryId: number) => {
    const pin = !pinned.includes(categoryId);
    const toggleState = () =>
      setPinned((prevPinned: Array<number>) =>
        prevPinned.includes(categoryId)
          ? prevPinned.filter((i) => i !== categoryId)
          : prevPinned.concat([categoryId])
      );

    toggleState(); // toggle local state

    // send request to the server
    pinCategory({
      body: {
        category_id: categoryId,
        pin
      }
    })
      .unwrap()
      .catch(() => {
        refetchPinnedCategories();
      });
  };

  const handleCheckboxClick = (clickedCategory: Category) => {
    const clickedCategoryId = Number(clickedCategory.id);
    const prevSelectedCategories = selectedCategories;
    const nextSelectedCategories = prevSelectedCategories.includes(clickedCategoryId)
      ? prevSelectedCategories.filter((c) => c !== clickedCategoryId)
      : [...prevSelectedCategories, clickedCategoryId];

    setSelectedCategories(nextSelectedCategories);
    onCheckedCategoriesChange(nextSelectedCategories);
  };

  return (
    <S.DropdownContent>
      <S.SubjectsSearch
        prefix={<S.SearchIcon />}
        name="categories"
        type="search"
        placeholder="Поиск тематики"
        allowClear
        value={searchStr}
        onChange={(e) => setSearchStr(e.target.value)}
        className="usiteful__subject-search-input"
      />
      {categories
        ?.filter((s: Category) => s.code_name.toLowerCase().includes(searchStr.toLocaleLowerCase()))
        .sort((a, b) => a.code_name.localeCompare(b.code_name, 'ru'))
        .map((item: Category) => (
          <S.SubjectItem
            pinned={pinned.includes(item.id)}
            order={pinned.length - pinned.indexOf(item.id)} // last elements in the array have higher priority: server returns them in a chronological order
            key={item.code_name}
          >
            <Checkbox
              checked={selectedCategories?.includes(Number(item.id))}
              onChange={() => handleCheckboxClick(item)}
            >
              {item.code_name}
            </Checkbox>
            <IconPin
              style={{ cursor: 'pointer' }}
              onClick={() => toggleCategoryPin(item.id)}
              className="usiteful__subject-pin-icon"
            />
          </S.SubjectItem>
        ))}
    </S.DropdownContent>
  );
}

export default memo(SubjectList);
