import { FC, ReactNode } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { zodResolver } from '@hookform/resolvers/zod';
import rechargeSchema from 'fsd/entities/balance/model/schemas/rechargeSchema';
import { getCurrencyIcon } from 'fsd/shared/lib/helpers/tools';
import { InputNumber } from 'fsd/shared/ui/Input';
import { type z } from 'zod';

import * as S from './styles';

type Props = {
  button: ReactNode;
  loading?: {
    button?: ReactNode;
  };
  presets?: {
    value: string;
    label: ReactNode;
  }[];
  defaultValue?: string;
  onFinish: SubmitHandler<z.infer<typeof rechargeSchema>>;
};

const Form: FC<Props> = ({ defaultValue, loading, presets, onFinish, button }) => {
  const { control, setValue, handleSubmit } = useForm({
    defaultValues: {
      amount: defaultValue ?? ''
    },
    resolver: zodResolver(rechargeSchema)
  });

  const handlePresetClick = useCallback(
    (presetValue: string) => {
      return () => {
        setValue('amount', presetValue, {
          shouldValidate: true
        });
      };
    },
    [setValue]
  );

  return (
    <S.Root onFinish={handleSubmit(onFinish)}>
      <FormItem control={control} name="amount">
        <InputNumber
          size="middle"
          placeholder={defaultValue ?? '0'}
          postfix={` ${getCurrencyIcon().toString()}`}
          min={500}
        />
      </FormItem>
      {presets?.length && (
        <S.PresetWrapper>
          {presets.map((preset) => (
            <S.Preset
              htmlType="button"
              key={preset.value}
              onClick={handlePresetClick(preset.value)}
            >
              {preset.label}
            </S.Preset>
          ))}
        </S.PresetWrapper>
      )}
      <S.Caption>
        Минимальная сумма пополнения <S.CaptionAccent>500 ₽</S.CaptionAccent>
      </S.Caption>
      <S.SubmitButton htmlType="submit" loading={Boolean(loading)}>
        {loading?.button || button}
      </S.SubmitButton>
    </S.Root>
  );
};

export default memo(Form);
