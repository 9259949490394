/* eslint-disable no-unused-vars */
import { isINNIndividual, isINNLegalEntity, isKPP, isOGRN, isOGRNIP } from '@utkonos/entrepreneur';
import { number, object, string } from 'zod';

const baseSchema = object({
  contragentType: number().positive(),
  organisation_type: number().positive(),
  organisation_name: string({
    required_error: 'Обязательное поле'
  }).min(1, 'Неверное название организации'),
  inn: string({
    required_error: 'Обязательное поле'
  }),
  ogrn: string({
    required_error: 'Обязательное поле'
  }),
  kpp: string().optional(),
  owner_full_name: string({
    required_error: 'Обязательное поле'
  }).regex(/^[a-zA-Zа-яА-ЯёЁ\s]+$/gi, 'ФИО может содержать только буквы и пробелы')
});

export const createBaseSchema = <T extends (schema: typeof baseSchema) => any>(creator: T) =>
  (creator(baseSchema) as typeof baseSchema)
    .refine(
      (data) => {
        switch (data.organisation_type) {
          case 1:
            if (!data.kpp) return false;
            return isKPP(data.kpp);
          default:
            return true;
        }
      },
      (data) => ({
        message: `Неверный КПП`,
        path: ['kpp'] // path of error
      })
    )
    .refine(
      (data) => {
        switch (data.organisation_type) {
          case 1:
            return isINNLegalEntity(data.inn);
          default:
            return isINNIndividual(data.inn);
        }
      },
      (data) => ({
        message: `Неверный ИНН для ${data.organisation_type === 1 ? 'ООО' : 'ИП'}`,
        path: ['inn'] // path of error
      })
    )
    .refine(
      (data) => {
        switch (data.organisation_type) {
          case 1:
            return isOGRN(data.ogrn);
          default:
            return isOGRNIP(data.ogrn);
        }
      },
      (data) => ({
        message: `Неверный ОГРН для ${data.organisation_type === 1 ? 'ООО' : 'ИП'}`,
        path: ['ogrn'] // path of error
      })
    ) as ReturnType<T>;

export default createBaseSchema((schema) => schema);
