import { FC, ReactNode } from 'react';
import { InfoBlock } from 'fsd/shared/ui/InfoBlock';

import Content from './Content';

import * as S from './styles';

type ModalProps = {
  open: boolean;
  purchaseId: string;
  actions?: {
    replace?: ReactNode;
    inviteLink?: ReactNode;
  };
  onClose: () => void;
};

const PreviewModal: FC<ModalProps> = ({ open, actions, onClose, purchaseId }) => {
  return (
    <S.Modal
      destroyOnClose
      title="Предпросмотр креатива"
      open={open}
      footer={
        <>
          {actions?.inviteLink}
          <InfoBlock size="m" variant="outlined-primary">
            <strong>ЕРИД</strong> отобразится после публикации поста
          </InfoBlock>
          {actions?.replace}
        </>
      }
      onClose={onClose}
    >
      <Content purchaseId={purchaseId} />
    </S.Modal>
  );
};

export default memo(PreviewModal);
