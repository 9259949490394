export enum DATE_RANGE_NAMES {
  today = 'Сегодня',
  yesterday = 'Вчера',
  week = 'Неделя',
  month = 'Месяц',
  all = 'За всё время'
}

export enum DATE_RANGE_VALUES {
  today = 'today',
  yesterday = 'yesterday',
  week = 'week',
  month = 'month',
  all = 'all'
}

export const DATE_RANGE = {
  today: {
    name: DATE_RANGE_NAMES.today,
    value: DATE_RANGE_VALUES.today
  },
  yesterday: {
    name: DATE_RANGE_NAMES.yesterday,
    value: DATE_RANGE_VALUES.yesterday
  },
  week: {
    name: DATE_RANGE_NAMES.week,
    value: DATE_RANGE_VALUES.week
  },
  month: {
    name: DATE_RANGE_NAMES.month,
    value: DATE_RANGE_VALUES.month
  },
  all: {
    name: DATE_RANGE_NAMES.all,
    value: DATE_RANGE_VALUES.all
  }
};

export enum CHANNEL_TYPE_NAMES {
  public = 'Публичный',
  private = 'Закрытый'
}

export const CHANNEL_TYPE = {
  public: {
    name: CHANNEL_TYPE_NAMES.public,
    value: true
  },
  private: {
    name: CHANNEL_TYPE_NAMES.private,
    value: false
  }
};

export type SortType =
  | 'views'
  | 'mentions'
  | 'er'
  | 'price'
  | 'participants'
  | 'participants_diff'
  | 'avg-daily-reaches'
  | 'reaches'
  | 'avg-daily-err'
  | 'channelName'
  | 'cpm'
  | 'cpp';

export type MinMaxType = {
  minItems?: number | string;
  maxItems?: number | string;
};

export interface FilterState {
  date_range: DATE_RANGE_VALUES;
  format_ids: number[];
  participants: MinMaxType;
  views: MinMaxType;
  avg_daily_reaches: MinMaxType;
  mentions: MinMaxType;
  er: MinMaxType;
  prices: MinMaxType;
  cpm: MinMaxType;
  categories: number[];
  languages: Array<number>;
  countries: number[];
  keywords: string[];
  is_public: boolean;
  is_verified: boolean;
  is_exchange_active: boolean;
  pagination: {
    offset: number;
    limit: number;
  };
  sort: {
    field: SortType;
    order: 'ASC' | 'DESC';
  };
  min_price: {
    currency_id: number;
    price: number;
  };
  max_price: {
    currency_id: number;
    price: number;
  };
}
