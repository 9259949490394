/* eslint-disable no-unused-vars */
import { ComponentProps, FC } from 'react';
import { PurchaseRead, useGetV1ExchangePurchasesContainersByContainerIdQuery } from 'api/api';
import { BalanceInvoiceInfo, BalanceRecharge, useCreateBalanceInvoice } from 'fsd/entities/balance';
import { useGetUserOrganisation } from 'fsd/entities/user';

type Props = {
  containerId: string;
  purchase: PurchaseRead;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
};

type ContentProps = ComponentProps<typeof BalanceRecharge.Content>;
type FormProps = ComponentProps<typeof BalanceRecharge.Form>;

const Modal: FC<Props> = ({ open, purchase, onOpenChange, containerId }) => {
  const { data: userOrganisation } = useGetUserOrganisation();
  const [step, setStep] = useState<'createInvoice' | 'invoiceCreated'>('createInvoice');
  const { data: containerPurchases } = useGetV1ExchangePurchasesContainersByContainerIdQuery({
    containerId
  });
  const [createInvoice, invoiceData] = useCreateBalanceInvoice();

  const handleFinish = useCallback<FormProps['onFinish']>(
    (data) => {
      if (userOrganisation?.data?.attributes) {
        createInvoice({
          amount: data.amount,
          organisation: userOrganisation.data.attributes
        }).then(() => {
          setStep('invoiceCreated');
        });
      }
    },
    [createInvoice, userOrganisation?.data?.attributes]
  );

  const allPurchasePrice = useMemo(
    () =>
      containerPurchases?.reduce((val, purchase) => {
        // @ts-ignore
        if (purchase.status && purchase.status !== 'new') return val;
        return val + (purchase.common_purchase_data?.cost ?? 0);
      }, 0) ?? 0,
    [containerPurchases]
  );

  const purchasePrice = purchase.common_purchase_data?.cost ?? 0;

  const isPriceEquals = allPurchasePrice === purchasePrice;

  const labels = useMemo(() => {
    if (step === 'invoiceCreated') {
      if (Number(invoiceData.amount) !== purchasePrice) {
        return [
          {
            title: Number(invoiceData.amount) === allPurchasePrice ? 'Всей корзины' : undefined,
            value: `${(+invoiceData.amount).toLocaleString('ru')} ₽`
          }
        ];
      }
    }

    const result: ContentProps['labels'] = [
      {
        title: purchase.channel.info.title ?? 'Эта заявка',
        value: `${((step === 'invoiceCreated' && +invoiceData.amount) || purchasePrice).toLocaleString('ru')} ₽`
      }
    ];

    if (step === 'createInvoice' && !isPriceEquals) {
      result?.push({
        title: 'Всей корзины',
        value: `${allPurchasePrice.toLocaleString('ru')} ₽`
      });
    }

    return result;
  }, [
    allPurchasePrice,
    invoiceData.amount,
    isPriceEquals,
    purchase.channel.info.title,
    purchasePrice,
    step
  ]);

  const presets = useMemo(() => {
    const allPriceWithMultiplier = allPurchasePrice * 2;
    const maxPrice = Math.max(100000, allPriceWithMultiplier - (allPriceWithMultiplier % 1000));
    const result: FormProps['presets'] = [
      {
        label: `${purchasePrice.toLocaleString('ru')} ₽`,
        value: purchasePrice.toString()
      },
      {
        label: `${allPurchasePrice.toLocaleString('ru')} ₽`,
        value: allPurchasePrice.toString()
      },
      {
        label: `${maxPrice.toLocaleString('ru')} ₽`,
        value: maxPrice.toString()
      }
    ];

    if (isPriceEquals) {
      result.splice(1, 1);
    }

    return result;
  }, [allPurchasePrice, isPriceEquals, purchasePrice]);

  useEffect(() => {
    if (!open) {
      setStep('createInvoice');
    }
  }, [open]);

  return (
    <BalanceRecharge.Modal
      open={open}
      onOpenChange={onOpenChange}
      info="Вы сможете отправить заявки только после пополнения баланса на достаточную сумму для их оплаты"
    >
      <BalanceRecharge.Content title="Пополните баланс для отправки заявки" labels={labels}>
        {step === 'createInvoice' ? (
          <BalanceRecharge.Form
            onFinish={handleFinish}
            button="Сгенерировать счет на оплату"
            loading={invoiceData.isLoading ? { button: 'Генерируем' } : undefined}
            defaultValue={purchasePrice.toString()}
            presets={presets}
          />
        ) : (
          <BalanceInvoiceInfo invoice={invoiceData} />
        )}
      </BalanceRecharge.Content>
    </BalanceRecharge.Modal>
  );
};

export default memo(Modal);
