import { Checkbox } from 'antd';
import { MergeComponentWithProps } from 'fsd/shared/types/MergeComponentWithProps';
import styled, { css } from 'styled-components';

export type RootProps = {
  $isError?: boolean;
};

export const Root: MergeComponentWithProps<typeof Checkbox, RootProps> = styled(Checkbox)<{
  $isError?: boolean;
}>`
  span {
    font-weight: 400;
    font-size: 12px;
    line-height: 133.33%;
    color: ${({ theme }) => theme.gunPowder};
    padding-right: 0;
    a {
      color: inherit;
      text-decoration: underline;
      &:hover {
        color: ${({ theme }) => theme.persianBlue};
      }
    }
  }
  .ant-checkbox-inner {
    ${({ $isError, theme }) =>
      $isError &&
      css`
        border-color: ${theme.hotspot}!important;
      `}
  }
`;
