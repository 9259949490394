/* eslint-disable no-param-reassign */
import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api as generatedAPI } from 'api/api';
import { generateInvalidateTagsByAPI } from 'api/generateInvalidateTags';
import { cartMiddleware, cartSliceReducer, reHydrateCart } from 'fsd/entities/cart';
import { channelReducer } from 'fsd/entities/channel';
import { channelTemporaryApi } from 'fsd/entities/channel/api';
import { strapiApi } from 'fsd/entities/strapi';

import channelPoolingStackReducer from '../../../../slices/channelPoolingStack/ChannelPoolingStackSlice';
import channelTabsReducer from '../../../../slices/channelTabs/ChannelTabsSlice';
import dateFilterSlice from '../../../../slices/dateFilter/DateFilterSlice';
import purchaseTabsReducer from '../../../../slices/purchaseTabs/PurchaseTabsSlice';

const { tagTypes, endpoints } = generateInvalidateTagsByAPI(generatedAPI);
const api = generatedAPI.enhanceEndpoints({
  addTagTypes: [...tagTypes, 'Metrics', 'StrapiClient', 'StrapiBalance', 'StrapiOrganisation'],
  endpoints: {
    ...endpoints,
    // override the base query to use an object DateRange as a simple query args
    patchV1UserChannel: {
      ...endpoints.patchV1UserChannel,
      invalidatesTags: [
        'V1Channels',
        'V1Exchange',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ...endpoints.patchV1UserChannel.invalidatesTags
      ]
    },
    postV1UserBalanceReplenishment: {
      ...endpoints.postV1UserBalanceReplenishment,
      // @ts-ignore
      invalidatesTags: [
        'StrapiClient',
        'StrapiBalance',
        ...endpoints.patchV1UserChannel.invalidatesTags
      ]
    },
    postV1UserBalanceWithdrawal: {
      ...endpoints.postV1UserBalanceWithdrawal,
      // @ts-ignore
      invalidatesTags: [
        'StrapiClient',
        'StrapiBalance',
        ...endpoints.postV1UserBalanceWithdrawal.invalidatesTags
      ]
    },
    getV2ChannelsByMetricType(endpoint) {
      endpoint.query = (queryArg) => ({
        url: `/v2/channels/${queryArg.metricType}`,
        params: {
          time_zone: queryArg.timeZone,
          ids: queryArg.ids,
          historical_metrics_loaded: queryArg.historicalMetricsLoaded,
          ...queryArg.dateRange
        }
      });

      endpoint.providesTags = (result, error, { ids }) =>
        ids.map((id) => ({ type: 'Metrics', id }));

      // endpoint.queryFn = (queryArg, api, extra, baseQuery) => {
      //   const { data: cache } = api.getState()

      //   // Фильтруем ID, проверяя, есть ли они в кэше
      //   const newIds = ids.filter(id => {
      //     const queryCacheKey = `fetchDataByIds(${id})`;
      //     return !cache[queryCacheKey];
      //   });

      //   if (newIds.length === 0) {
      //     return { data: [] }; // Возвращаем пустой массив, если все ID в кеше
      //   }
      //   return baseQuery({
      //     body:
      //   })
      // }
    },
    getV1ExchangePurchasesContainersByContainerId: {
      ...endpoints.getV1ExchangePurchasesContainersByContainerId,
      providesTags: [
        ...(endpoints.getV1ExchangePurchasesContainersByContainerId.providesTags ?? []),
        'V1Exchange',
        'Purchase',
        'Container'
      ]
    },
    postV1ExchangePurchaseContainersCreate: {
      ...endpoints.postV1ExchangePurchaseContainersCreate,
      invalidatesTags: [
        ...(endpoints.postV1ExchangePurchaseContainersCreate.invalidatesTags ?? []),
        'V1Exchange',
        'Purchase',
        'Container'
      ]
    },
    getV1ChannelsByChannelIdPostsAndPostIdType(endpoint) {
      endpoint.query = (queryArg) => ({
        url: `/channels/${queryArg.channelId}/posts/${queryArg.postId}/${queryArg.type}`,
        params: {
          time_zone: queryArg.timeZone,
          ...queryArg.dateRange
        }
      });
    },
    channels: {
      ...endpoints.channels
      // serializeQueryArgs: ({ endpointName }) => {
      //   return endpointName;
      // },
      // // Always merge incoming data to the cache entry
      // merge: (currentCache, newItems) => {
      //   currentCache.channels?.push(...(newItems.channels ?? []));
      // },
      // // Refetch when the page arg changes
      // forceRefetch({ currentArg, previousArg }) {
      //   return currentArg. !== previousArg;
      // }
    },
    // TS-1654 add timeout to the query
    getV1ChannelsFind(endpoint) {
      endpoint.query = (queryArg) => ({
        url: `/v1/channels/find`,
        params: { keywords: queryArg.keywords },
        timeout: 1000 * 60 * 3
      });
    },
    // custom invalidation
    getV1ChannelsByChannelIdDownloadStatus: {
      providesTags: ['Metrics']
    },
    postV1ChannelsHistoricalMetrics: {
      invalidatesTags: ['Metrics']
    },
    // where delete organisation, cache clear and invalidate
    getV1UserOrganisationByOrganisationId: {
      // eslint-disable-next-line no-shadow
      onQueryStarted: async (args, api) => {
        try {
          await api.queryFulfilled;
        } catch (e) {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars,@typescript-eslint/ban-ts-comment
          // @ts-ignore
          // eslint-disable-next-line no-return-assign, no-param-reassign, no-unused-vars
          api.updateCachedData((dataDraft) => (dataDraft = null));
        }
      }
    }
  }
});

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [strapiApi.reducerPath]: strapiApi.reducer,
    [channelTemporaryApi.reducerPath]: channelTemporaryApi.reducer,
    cart: cartSliceReducer,
    channelTabs: channelTabsReducer,
    channelPoolingStack: channelPoolingStackReducer,
    purchaseTabs: purchaseTabsReducer,
    dateFilter: dateFilterSlice,
    channel: channelReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      api.middleware,
      strapiApi.middleware,
      channelTemporaryApi.middleware,
      cartMiddleware
    ),
  preloadedState: {
    cart: reHydrateCart()
  }
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export function setupStore(preloadedState?: RootState) {
  return configureStore({
    reducer: {
      [api.reducerPath]: api.reducer,
      [strapiApi.reducerPath]: strapiApi.reducer,
      [channelTemporaryApi.reducerPath]: channelTemporaryApi.reducer,
      cart: cartSliceReducer,
      channelTabs: channelTabsReducer,
      channelPoolingStack: channelPoolingStackReducer,
      purchaseTabs: purchaseTabsReducer,
      dateFilter: dateFilterSlice,
      channel: channelReducer
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(
        api.middleware,
        strapiApi.middleware,
        channelTemporaryApi.middleware,
        cartMiddleware
      ),
    preloadedState: {
      cart: reHydrateCart(),
      ...preloadedState
    } as RootState
  });
}
