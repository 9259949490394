import { FC, ReactNode } from 'react';
import { ModalProps } from 'antd5';
import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import { useMediaQuery } from 'fsd/shared/modules/media-queries';

import Content from './Content';

import * as S from './styles';

type Props = Omit<ModalProps, 'style' | 'height' | 'width' | 'modalRender'> & {
  onClose?: () => void;
  footer?: ReactNode;
};

const FullPage: FC<Props> = ({ children, title, onClose, footer, ...restProps }) => {
  const isMobile = useMediaQuery(MEDIA_DEVICE.mobile.to);
  const handleClose = useCallback(() => {
    onClose?.();
  }, [onClose]);

  return (
    <S.Root
      // eslint-disable-next-line react/no-unstable-nested-components
      modalRender={() => (
        <Content onClose={handleClose} title={title} footer={footer}>
          {children}
        </Content>
      )}
      centered={isMobile}
      onClose={handleClose}
      onCancel={handleClose}
      transitionName={isMobile ? 'ant-move-down' : 'ant-move-right'}
      width={450}
      {...restProps}
    />
  );
};

export default memo(FullPage);
