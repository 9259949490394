import { FC, PropsWithChildren } from 'react';
import { PurchaseRead } from 'api/api';
import dayjs, { Dayjs } from 'dayjs';
import { isCanEditPurchaseDate, useUpdatePurchaseDateAndTime } from 'fsd/entities/purchase';
import { IconCalendar } from 'fsd/shared/icons';
import { OutlinedButton } from 'fsd/shared/ui/Buttons/OutlinedButton';
import { DatePicker } from 'fsd/shared/ui/Date';

type Props = {
  purchase: PurchaseRead;
  block?: boolean;
};

const createDateByPurchase = ({
  date,
  time,
  publishedAt
}: {
  time?: number;
  date?: string;
  publishedAt?: string | null;
}) => {
  if (publishedAt) {
    return dayjs(publishedAt);
  }
  if (typeof time !== 'number' || !date) return null;
  return dayjs(date)
    .startOf('day')
    .minute(time ?? 0);
};

const Button: FC<Props> = ({ purchase, block }) => {
  const [updatePurchaseDateAndTime, { isLoading }] = useUpdatePurchaseDateAndTime();
  const [open, setOpen] = useState(false);

  const dateByPurchase = useMemo(() => {
    return createDateByPurchase({
      time: purchase.time,
      date: purchase.common_purchase_data?.date,
      publishedAt: purchase.published_at
    });
  }, [purchase.common_purchase_data?.date, purchase.published_at, purchase.time]);

  const [value, setValue] = useState<Dayjs | null>(dateByPurchase);

  const isDisabled = isLoading || (purchase.status && !isCanEditPurchaseDate(purchase.status));

  const handleClick = useCallback(() => {
    setOpen((val) => !val && true);
  }, []);

  const handleOpenChange = useCallback((open: boolean) => {
    setOpen(open);
  }, []);

  const handleChange = useCallback(
    (date?: Dayjs) => {
      if (date) {
        const parsedDate = dayjs(date);
        const prevValue = value;
        setValue(parsedDate);
        updatePurchaseDateAndTime(purchase, parsedDate.toDate())
          .unwrap()
          .then(() => {
            setValue(parsedDate);
          })
          .catch(() => {
            setValue(prevValue);
          });
      }
    },
    [value, updatePurchaseDateAndTime, purchase]
  );

  useEffect(() => {
    setValue(dateByPurchase);
  }, [dateByPurchase]);

  const InputWrapper = useCallback<FC<PropsWithChildren>>(
    ({ children }) => {
      return (
        <OutlinedButton
          block
          startIcon={<IconCalendar />}
          onClick={handleClick}
          disabled={isDisabled}
        >
          {children}
        </OutlinedButton>
      );
    },
    [handleClick, isDisabled]
  );

  return (
    <DatePicker
      disabled={isDisabled}
      open={open}
      value={value}
      onChange={handleChange}
      style={{ width: block ? '100%' : 152 }}
      minDate={dayjs()}
      minTime={dayjs()}
      onOpenChange={handleOpenChange}
      okButton="Сохранить"
      format="DD.MM | HH:mm"
      showTime
      placement="bottomRight"
      minuteStep={5}
      placeholder="Дата и время"
      inputWrapper={InputWrapper}
    />
  );
};

export default memo(Button);
