import { FC, PropsWithChildren } from 'react';
import { Control } from 'react-hook-form';
import { FormItem } from 'react-hook-form-antd';
import { Checkbox } from 'fsd/shared/ui/Checkbox';

type Props = {
  control: Control<any>;
};

const AcceptOffer: FC<PropsWithChildren<Props>> = ({ control, children }) => {
  return (
    <FormItem control={control} required name="acceptOffer">
      <Checkbox>{children}</Checkbox>
    </FormItem>
  );
};

export default memo(AcceptOffer);
