import { FC } from 'react';
import { IconAttach } from 'fsd/shared/icons';
import { FieldBlock } from 'fsd/shared/ui/FieldBlock';
import { LinkField } from 'fsd/shared/ui/LinkField';

type InviteLinkFieldProps = {
  url: string;
};

const InviteLinkField: FC<InviteLinkFieldProps> = ({ url }) => {
  return (
    <FieldBlock label="Пригласительная ссылка">
      <LinkField url={url} icon={<IconAttach />} />
    </FieldBlock>
  );
};

export default memo(InviteLinkField);
