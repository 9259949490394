import { FC, ReactNode } from 'react';
import { copyURL } from 'fsd/shared/lib/helpers/tools';

import { Icon } from '../../Icon';

import * as S from './styles';

type LinkFieldProps = {
  icon: ReactNode;
  url: string;
};

const LinkField: FC<LinkFieldProps> = ({ icon, url }) => {
  return (
    <S.LinkContainer
      onClick={(e) => {
        copyURL(e, url);
      }}
    >
      {icon && <Icon color={theme.americanGreen}>{icon}</Icon>}
      <S.LinkText>{url}</S.LinkText>
    </S.LinkContainer>
  );
};

export default LinkField;
