import { FC, useCallback } from 'react';
import { Id5 } from 'api/api';
import { useSendPurchaseCreative } from 'fsd/features/sendPurchaseCreative/cross';
import { Button } from 'fsd/shared/ui/Buttons';

type Props = {
  purchaseId: Id5;
};

const ReplaceCreativeButton: FC<Props> = ({ purchaseId }) => {
  const [send, { isLoading }] = useSendPurchaseCreative();

  const handleClick = useCallback(() => {
    send(purchaseId);
  }, [purchaseId, send]);

  return (
    <Button size="large" type="primary" onClick={handleClick} disabled={isLoading}>
      Заменить креатив
    </Button>
  );
};

export default ReplaceCreativeButton;
