import { FC, PropsWithChildren, ReactNode } from 'react';

import Header from '../Header';

import * as S from './styles';

type Props = {
  title?: ReactNode;
  footer?: ReactNode;
  onClose: () => void;
};

const Content: FC<PropsWithChildren<Props>> = ({ children, title, footer, onClose }) => {
  return (
    <S.Root>
      <Header onClose={onClose}>{title}</Header>
      <S.Body>{children}</S.Body>
      {footer && <S.Footer>{footer}</S.Footer>}
    </S.Root>
  );
};

export default Content;
