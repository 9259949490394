import { number, object, preprocess } from 'zod';

export default object({
  amount: preprocess(
    Number,
    number({
      required_error: 'Введите сумму',
      invalid_type_error: 'Введите сумму'
    })
      .positive('Укажите значение')
      .min(500, 'Минимальная сумма пополнения 500')
  ).transform((value) => (value ? value.toString() : '500'))
});
