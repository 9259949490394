import { CSSProperties, FC, PropsWithChildren } from 'react';

import Button from '../Button';
import Input from '../Input';
import { ContextProps, PickerContext } from '../lib/context';
import useDisabledTime from '../lib/useDisabledTime';
import Panel from '../Panel';
import { PickerProps } from '../types';

import * as S from './styles';

type Props = PickerProps & {
  inputWrapper?: FC<PropsWithChildren>;
  inputStyle?: CSSProperties;
};

const DefaultInputWrapper: FC<PropsWithChildren> = ({ children }) => {
  return children;
};

const Picker: FC<Props> = ({
  format,
  okButton,
  minTime,
  okButtonRender,
  okDisabled,
  inputStyle,
  inputWrapper: InputWrapper = DefaultInputWrapper,
  ...restProps
}) => {
  const disabledTime = useDisabledTime(minTime);
  const contextValue = useMemo(
    (): ContextProps => ({ format, okButton, okDisabled }),
    [format, okButton, okDisabled]
  );
  const InputComponent = forwardRef((inputProps: any, ref) => {
    return (
      <InputWrapper>
        <Input {...inputProps} ref={ref} style={inputStyle} />
      </InputWrapper>
    );
  });
  return (
    <PickerContext.Provider value={contextValue}>
      <S.Root
        suffixIcon={null}
        allowClear={false}
        showNow={false}
        inputReadOnly
        transitionName="ant-fade"
        // eslint-disable-next-line react/no-unstable-nested-components
        panelRender={(content) => <Panel>{content}</Panel>}
        components={{
          input: InputComponent,
          button: okButtonRender ?? Button
        }}
        format={format}
        disabledTime={disabledTime}
        {...restProps}
      />
    </PickerContext.Provider>
  );
};

export default memo(Picker);
