import { emptySplitApi as api } from './emptyApi';
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    postV1SignupEmail: build.mutation<PostV1SignupEmailApiResponse, PostV1SignupEmailApiArg>({
      query: (queryArg) => ({
        url: `/v1/signup/email`,
        method: 'POST',
        body: queryArg.signupWithEmail
      })
    }),
    getV1SignupTg: build.query<GetV1SignupTgApiResponse, GetV1SignupTgApiArg>({
      query: (queryArg) => ({ url: `/v1/signup/tg`, params: { domain: queryArg.domain } })
    }),
    postV1SignupTg: build.mutation<PostV1SignupTgApiResponse, PostV1SignupTgApiArg>({
      query: (queryArg) => ({ url: `/v1/signup/tg`, method: 'POST', body: queryArg.body })
    }),
    postV1ConfirmEmail: build.mutation<PostV1ConfirmEmailApiResponse, PostV1ConfirmEmailApiArg>({
      query: (queryArg) => ({
        url: `/v1/confirm/email`,
        method: 'POST',
        body: queryArg.emailConfirmation
      })
    }),
    postV1SigninEmail: build.mutation<PostV1SigninEmailApiResponse, PostV1SigninEmailApiArg>({
      query: (queryArg) => ({
        url: `/v1/signin/email`,
        method: 'POST',
        body: queryArg.signInWithEmail
      })
    }),
    postV1SigninTg: build.mutation<PostV1SigninTgApiResponse, PostV1SigninTgApiArg>({
      query: (queryArg) => ({ url: `/v1/signin/tg`, method: 'POST', body: queryArg.body })
    }),
    getV1User: build.query<GetV1UserApiResponse, GetV1UserApiArg>({
      query: () => ({ url: `/v1/user` })
    }),
    patchV1User: build.mutation<PatchV1UserApiResponse, PatchV1UserApiArg>({
      query: (queryArg) => ({ url: `/v1/user`, method: 'PATCH', body: queryArg.userUpdate })
    }),
    postV1UserBalanceReplenishment: build.mutation<
      PostV1UserBalanceReplenishmentApiResponse,
      PostV1UserBalanceReplenishmentApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/balance/replenishment`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1UserBalanceWithdrawal: build.mutation<
      PostV1UserBalanceWithdrawalApiResponse,
      PostV1UserBalanceWithdrawalApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/balance/withdrawal`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1UserOrganisationAddMember: build.mutation<
      PostV1UserOrganisationAddMemberApiResponse,
      PostV1UserOrganisationAddMemberApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/organisation/addMember`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1UserChannel: build.mutation<PostV1UserChannelApiResponse, PostV1UserChannelApiArg>({
      query: (queryArg) => ({ url: `/v1/user/channel`, method: 'POST', body: queryArg.body })
    }),
    patchV1UserChannel: build.mutation<PatchV1UserChannelApiResponse, PatchV1UserChannelApiArg>({
      query: (queryArg) => ({ url: `/v1/user/channel`, method: 'PATCH', body: queryArg.body })
    }),
    getV1UserChannels: build.query<GetV1UserChannelsApiResponse, GetV1UserChannelsApiArg>({
      query: () => ({ url: `/v1/user/channels` })
    }),
    getV1UserChannelByChannelIdContacts: build.query<
      GetV1UserChannelByChannelIdContactsApiResponse,
      GetV1UserChannelByChannelIdContactsApiArg
    >({
      query: (queryArg) => ({ url: `/v1/user/channel/${queryArg.channelId}/contacts` })
    }),
    getV1UserChannelFormats: build.query<
      GetV1UserChannelFormatsApiResponse,
      GetV1UserChannelFormatsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/channel/formats`,
        params: { formats_id: queryArg.formatsId }
      })
    }),
    getV2UserChannelFormats: build.query<
      GetV2UserChannelFormatsApiResponse,
      GetV2UserChannelFormatsApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/user/channel/formats`,
        params: { formats_id: queryArg.formatsId }
      })
    }),
    getV1UserChannelFormatsParameters: build.query<
      GetV1UserChannelFormatsParametersApiResponse,
      GetV1UserChannelFormatsParametersApiArg
    >({
      query: () => ({ url: `/v1/user/channel/formats/parameters` })
    }),
    postV1UserChannelUpdateFormat: build.mutation<
      PostV1UserChannelUpdateFormatApiResponse,
      PostV1UserChannelUpdateFormatApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/channel/update_format`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1UserChannelVerificationByChannelId: build.mutation<
      PostV1UserChannelVerificationByChannelIdApiResponse,
      PostV1UserChannelVerificationByChannelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/channel/verification/${queryArg.channelId}`,
        method: 'POST'
      })
    }),
    getV1UserChannelCurrencies: build.query<
      GetV1UserChannelCurrenciesApiResponse,
      GetV1UserChannelCurrenciesApiArg
    >({
      query: () => ({ url: `/v1/user/channel/currencies` })
    }),
    postV1UserCategoryPin: build.mutation<
      PostV1UserCategoryPinApiResponse,
      PostV1UserCategoryPinApiArg
    >({
      query: (queryArg) => ({ url: `/v1/user/category/pin`, method: 'POST', body: queryArg.body })
    }),
    getV1UserCategoryPinned: build.query<
      GetV1UserCategoryPinnedApiResponse,
      GetV1UserCategoryPinnedApiArg
    >({
      query: () => ({ url: `/v1/user/category/pinned` })
    }),
    postV1UserOrganisation: build.mutation<
      PostV1UserOrganisationApiResponse,
      PostV1UserOrganisationApiArg
    >({
      query: (queryArg) => ({ url: `/v1/user/organisation`, method: 'POST', body: queryArg.body })
    }),
    patchV1UserOrganisationByOrganisationId: build.mutation<
      PatchV1UserOrganisationByOrganisationIdApiResponse,
      PatchV1UserOrganisationByOrganisationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/organisation/${queryArg.organisationId}`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    getV1UserOrganisationByOrganisationId: build.query<
      GetV1UserOrganisationByOrganisationIdApiResponse,
      GetV1UserOrganisationByOrganisationIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/user/organisation/${queryArg.organisationId}` })
    }),
    deleteV1UserOrganisationByOrganisationId: build.mutation<
      DeleteV1UserOrganisationByOrganisationIdApiResponse,
      DeleteV1UserOrganisationByOrganisationIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/user/organisation/${queryArg.organisationId}`,
        method: 'DELETE'
      })
    }),
    getV1OrganisationTypes: build.query<
      GetV1OrganisationTypesApiResponse,
      GetV1OrganisationTypesApiArg
    >({
      query: () => ({ url: `/v1/organisation/types` })
    }),
    channels: build.query<ChannelsApiResponse, ChannelsApiArg>({
      query: (queryArg) => ({ url: `/v1/channels`, params: { filter: queryArg.filter } })
    }),
    getV1ChannelsList: build.query<GetV1ChannelsListApiResponse, GetV1ChannelsListApiArg>({
      query: (queryArg) => ({ url: `/v1/channels/list`, params: { ids: queryArg.ids } })
    }),
    getV1ChannelsFind: build.query<GetV1ChannelsFindApiResponse, GetV1ChannelsFindApiArg>({
      query: (queryArg) => ({ url: `/v1/channels/find`, params: { keywords: queryArg.keywords } })
    }),
    getV1ChannelsFilterFind: build.query<
      GetV1ChannelsFilterFindApiResponse,
      GetV1ChannelsFilterFindApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/filter/find`,
        params: { keyword: queryArg.keyword }
      })
    }),
    postV1ChannelsJoin: build.mutation<PostV1ChannelsJoinApiResponse, PostV1ChannelsJoinApiArg>({
      query: (queryArg) => ({
        url: `/v1/channels/join`,
        method: 'POST',
        body: queryArg.joinAdminBot
      })
    }),
    postV1ChannelsHistoricalMetrics: build.mutation<
      PostV1ChannelsHistoricalMetricsApiResponse,
      PostV1ChannelsHistoricalMetricsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/historical_metrics`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getV1ChannelsByChannelId: build.query<
      GetV1ChannelsByChannelIdApiResponse,
      GetV1ChannelsByChannelIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/channels/${queryArg.channelId}` })
    }),
    getV1ChannelsByChannelIdInvites: build.query<
      GetV1ChannelsByChannelIdInvitesApiResponse,
      GetV1ChannelsByChannelIdInvitesApiArg
    >({
      query: (queryArg) => ({ url: `/v1/channels/${queryArg.channelId}/invites` })
    }),
    getV1ChannelsByChannelIdAndTypeTimeWindow: build.query<
      GetV1ChannelsByChannelIdAndTypeTimeWindowApiResponse,
      GetV1ChannelsByChannelIdAndTypeTimeWindowApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/${queryArg['type']}/${queryArg.timeWindow}`,
        params: { time_zone: queryArg.timeZone }
      })
    }),
    getV2ChannelsByMetricType: build.query<
      GetV2ChannelsByMetricTypeApiResponse,
      GetV2ChannelsByMetricTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/channels/${queryArg.metricType}`,
        params: {
          time_zone: queryArg.timeZone,
          date_range: queryArg.dateRange,
          ids: queryArg.ids,
          historical_metrics_loaded: queryArg.historicalMetricsLoaded
        }
      })
    }),
    getV1ChannelsByChannelIdParticipantsDetails: build.query<
      GetV1ChannelsByChannelIdParticipantsDetailsApiResponse,
      GetV1ChannelsByChannelIdParticipantsDetailsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/participants/details`,
        params: { date_range: queryArg.dateRange }
      })
    }),
    getV1ChannelsByChannelIdAdvMentions: build.query<
      GetV1ChannelsByChannelIdAdvMentionsApiResponse,
      GetV1ChannelsByChannelIdAdvMentionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/adv/mentions`,
        params: {
          categories: queryArg.categories,
          sort_by: queryArg.sortBy,
          order: queryArg.order,
          adv_type: queryArg.advType
        }
      })
    }),
    getV1ChannelsByChannelIdTimeline: build.query<
      GetV1ChannelsByChannelIdTimelineApiResponse,
      GetV1ChannelsByChannelIdTimelineApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/timeline`,
        params: {
          date: queryArg.date,
          direction: queryArg.direction,
          limit: queryArg.limit,
          with_reposts: queryArg.withReposts
        }
      })
    }),
    getV1ChannelsByChannelIdTimelineEvents: build.query<
      GetV1ChannelsByChannelIdTimelineEventsApiResponse,
      GetV1ChannelsByChannelIdTimelineEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/timeline_events`,
        params: {
          last_date: queryArg.lastDate,
          limit: queryArg.limit,
          with_reposts: queryArg.withReposts,
          historical_metrics_loaded: queryArg.historicalMetricsLoaded,
          direction: queryArg.direction
        }
      })
    }),
    getV2ChannelsByChannelIdTimelineEvents: build.query<
      GetV2ChannelsByChannelIdTimelineEventsApiResponse,
      GetV2ChannelsByChannelIdTimelineEventsApiArg
    >({
      query: (queryArg) => ({
        url: `/v2/channels/${queryArg.channelId}/timeline_events`,
        params: {
          last_date: queryArg.lastDate,
          limit: queryArg.limit,
          with_reposts: queryArg.withReposts,
          historical_metrics_loaded: queryArg.historicalMetricsLoaded,
          direction: queryArg.direction
        }
      })
    }),
    getV1ChannelsByChannelIdPosts: build.query<
      GetV1ChannelsByChannelIdPostsApiResponse,
      GetV1ChannelsByChannelIdPostsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/posts`,
        params: { navigation: queryArg.navigation }
      })
    }),
    getV1ChannelsByChannelIdPostsMentions: build.query<
      GetV1ChannelsByChannelIdPostsMentionsApiResponse,
      GetV1ChannelsByChannelIdPostsMentionsApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/posts/mentions`,
        params: { navigation: queryArg.navigation }
      })
    }),
    getV1ChannelsByChannelIdMentionsSources: build.query<
      GetV1ChannelsByChannelIdMentionsSourcesApiResponse,
      GetV1ChannelsByChannelIdMentionsSourcesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/mentions/sources`,
        params: { date_range: queryArg.dateRange, time_zone: queryArg.timeZone }
      })
    }),
    getV1ChannelsByChannelIdDownloadStatus: build.query<
      GetV1ChannelsByChannelIdDownloadStatusApiResponse,
      GetV1ChannelsByChannelIdDownloadStatusApiArg
    >({
      query: (queryArg) => ({ url: `/v1/channels/${queryArg.channelId}/download_status` })
    }),
    getV1ChannelsByChannelIdPostsAndPostId: build.query<
      GetV1ChannelsByChannelIdPostsAndPostIdApiResponse,
      GetV1ChannelsByChannelIdPostsAndPostIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/channels/${queryArg.channelId}/posts/${queryArg.postId}` })
    }),
    getV1ChannelsByChannelIdPostsAndPostIdType: build.query<
      GetV1ChannelsByChannelIdPostsAndPostIdTypeApiResponse,
      GetV1ChannelsByChannelIdPostsAndPostIdTypeApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/posts/${queryArg.postId}/${queryArg['type']}`,
        params: { date_range: queryArg.dateRange, time_zone: queryArg.timeZone }
      })
    }),
    getV1ChannelsByChannelIdPostsAndPostIdSubscribers: build.query<
      GetV1ChannelsByChannelIdPostsAndPostIdSubscribersApiResponse,
      GetV1ChannelsByChannelIdPostsAndPostIdSubscribersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/posts/${queryArg.postId}/subscribers`,
        params: { date_range: queryArg.dateRange, time_zone: queryArg.timeZone }
      })
    }),
    getV1ChannelsByChannelIdAdv: build.query<
      GetV1ChannelsByChannelIdAdvApiResponse,
      GetV1ChannelsByChannelIdAdvApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/channels/${queryArg.channelId}/adv`,
        params: {
          with_repost: queryArg.withRepost,
          with_parallel: queryArg.withParallel,
          categories: queryArg.categories,
          sort_by: queryArg.sortBy,
          order: queryArg.order,
          adv_type: queryArg.advType
        }
      })
    }),
    postV1InviteCreate: build.mutation<PostV1InviteCreateApiResponse, PostV1InviteCreateApiArg>({
      query: (queryArg) => ({
        url: `/v1/invite/create`,
        method: 'POST',
        body: queryArg.createInviteLinkRequest
      })
    }),
    getV1Categories: build.query<GetV1CategoriesApiResponse, GetV1CategoriesApiArg>({
      query: () => ({ url: `/v1/categories` })
    }),
    getV1Languages: build.query<GetV1LanguagesApiResponse, GetV1LanguagesApiArg>({
      query: () => ({ url: `/v1/languages` })
    }),
    getV1Countries: build.query<GetV1CountriesApiResponse, GetV1CountriesApiArg>({
      query: () => ({ url: `/v1/countries` })
    }),
    getV1ExchangeFormat: build.query<GetV1ExchangeFormatApiResponse, GetV1ExchangeFormatApiArg>({
      query: () => ({ url: `/v1/exchange/format` })
    }),
    postV1ExchangeFormat: build.mutation<
      PostV1ExchangeFormatApiResponse,
      PostV1ExchangeFormatApiArg
    >({
      query: (queryArg) => ({ url: `/v1/exchange/format`, method: 'POST', body: queryArg.body })
    }),
    getV1ExchangeCurrency: build.query<
      GetV1ExchangeCurrencyApiResponse,
      GetV1ExchangeCurrencyApiArg
    >({
      query: () => ({ url: `/v1/exchange/currency` })
    }),
    getV1ExchangeCurrencyByBaseCurrency: build.query<
      GetV1ExchangeCurrencyByBaseCurrencyApiResponse,
      GetV1ExchangeCurrencyByBaseCurrencyApiArg
    >({
      query: (queryArg) => ({ url: `/v1/exchange/currency/${queryArg.baseCurrency}` })
    }),
    getV1ExchangePurchasesContainers: build.query<
      GetV1ExchangePurchasesContainersApiResponse,
      GetV1ExchangePurchasesContainersApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchases/containers`,
        params: { is_active: queryArg.isActive }
      })
    }),
    getV1ExchangePurchasesContainersByContainerId: build.query<
      GetV1ExchangePurchasesContainersByContainerIdApiResponse,
      GetV1ExchangePurchasesContainersByContainerIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/exchange/purchases/containers/${queryArg.containerId}` })
    }),
    postV1ExchangePurchaseContainer: build.mutation<
      PostV1ExchangePurchaseContainerApiResponse,
      PostV1ExchangePurchaseContainerApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/container`,
        method: 'POST',
        body: queryArg.createContainer
      })
    }),
    patchV1ExchangePurchaseContainer: build.mutation<
      PatchV1ExchangePurchaseContainerApiResponse,
      PatchV1ExchangePurchaseContainerApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/container`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    patchV1ExchangePurchaseContainerChannelId: build.mutation<
      PatchV1ExchangePurchaseContainerChannelIdApiResponse,
      PatchV1ExchangePurchaseContainerChannelIdApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/container/channel/id`,
        method: 'PATCH',
        body: queryArg.body
      })
    }),
    patchV1ExchangePurchaseContainerClone: build.mutation<
      PatchV1ExchangePurchaseContainerCloneApiResponse,
      PatchV1ExchangePurchaseContainerCloneApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/container/clone`,
        method: 'PATCH',
        body: queryArg.containerIdList
      })
    }),
    patchV1ExchangePurchaseContainersChangeStatus: build.mutation<
      PatchV1ExchangePurchaseContainersChangeStatusApiResponse,
      PatchV1ExchangePurchaseContainersChangeStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/containers/change_status`,
        method: 'PATCH',
        body: queryArg.changeActivityStatusForPurchaseLis
      })
    }),
    postV1ExchangePurchaseContainersCreate: build.mutation<
      PostV1ExchangePurchaseContainersCreateApiResponse,
      PostV1ExchangePurchaseContainersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/containers/create`,
        method: 'POST',
        body: queryArg.createPurchase
      })
    }),
    postV1ExchangePurchasesContainersCreate: build.mutation<
      PostV1ExchangePurchasesContainersCreateApiResponse,
      PostV1ExchangePurchasesContainersCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchases/containers/create`,
        method: 'POST',
        body: queryArg.createPurchaseList
      })
    }),
    postV1ExchangePurchasesContainerNewCreate: build.mutation<
      PostV1ExchangePurchasesContainerNewCreateApiResponse,
      PostV1ExchangePurchasesContainerNewCreateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchases/container/new/create`,
        method: 'POST',
        body: queryArg.createPurchaseList
      })
    }),
    getV1ExchangePurchaseByPurchaseId: build.query<
      GetV1ExchangePurchaseByPurchaseIdApiResponse,
      GetV1ExchangePurchaseByPurchaseIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/exchange/purchase/${queryArg.purchaseId}` })
    }),
    patchV1ExchangePurchaseUpdate: build.mutation<
      PatchV1ExchangePurchaseUpdateApiResponse,
      PatchV1ExchangePurchaseUpdateApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/update`,
        method: 'PATCH',
        body: queryArg.updatePurchase
      })
    }),
    patchV1ExchangePurchaseStatus: build.mutation<
      PatchV1ExchangePurchaseStatusApiResponse,
      PatchV1ExchangePurchaseStatusApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/status`,
        method: 'PATCH',
        body: queryArg.updatePurchaseStatus
      })
    }),
    patchV1ExchangePurchaseDelete: build.mutation<
      PatchV1ExchangePurchaseDeleteApiResponse,
      PatchV1ExchangePurchaseDeleteApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/purchase/delete`,
        method: 'PATCH',
        body: queryArg.purchaseIdList
      })
    }),
    getV1ExchangePurchaseCreativeByCreativeId: build.query<
      GetV1ExchangePurchaseCreativeByCreativeIdApiResponse,
      GetV1ExchangePurchaseCreativeByCreativeIdApiArg
    >({
      query: (queryArg) => ({ url: `/v1/exchange/purchase/creative/${queryArg.creativeId}` })
    }),
    getV1ExchangePurchaseByPurchaseIdCreative: build.query<
      GetV1ExchangePurchaseByPurchaseIdCreativeApiResponse,
      GetV1ExchangePurchaseByPurchaseIdCreativeApiArg
    >({
      query: (queryArg) => ({ url: `/v1/exchange/purchase/${queryArg.purchaseId}/creative` })
    }),
    postV1ExchangeS3UploadFile: build.mutation<
      PostV1ExchangeS3UploadFileApiResponse,
      PostV1ExchangeS3UploadFileApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/s3/upload/file`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1ExchangeS3UploadFiles: build.mutation<
      PostV1ExchangeS3UploadFilesApiResponse,
      PostV1ExchangeS3UploadFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/s3/upload/files`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    postV1ExchangeS3DeleteFiles: build.mutation<
      PostV1ExchangeS3DeleteFilesApiResponse,
      PostV1ExchangeS3DeleteFilesApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/s3/delete/files`,
        method: 'POST',
        body: queryArg.body
      })
    }),
    getV1ExchangeAuthorizedChannels: build.query<
      GetV1ExchangeAuthorizedChannelsApiResponse,
      GetV1ExchangeAuthorizedChannelsApiArg
    >({
      query: () => ({ url: `/v1/exchange/authorized/channels` })
    }),
    postV1ExchangeAuthorizedChannel: build.mutation<
      PostV1ExchangeAuthorizedChannelApiResponse,
      PostV1ExchangeAuthorizedChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/authorized/channel`,
        method: 'POST',
        body: queryArg.authorizedChannel
      })
    }),
    patchV1ExchangeAuthorizedChannel: build.mutation<
      PatchV1ExchangeAuthorizedChannelApiResponse,
      PatchV1ExchangeAuthorizedChannelApiArg
    >({
      query: (queryArg) => ({
        url: `/v1/exchange/authorized/channel`,
        method: 'PATCH',
        body: queryArg.authorizedChannel
      })
    }),
    getV1ExchangeMultipliers: build.query<
      GetV1ExchangeMultipliersApiResponse,
      GetV1ExchangeMultipliersApiArg
    >({
      query: () => ({ url: `/v1/exchange/multipliers` })
    })
  }),
  overrideExisting: false
});
export { injectedRtkApi as api };
export type PostV1SignupEmailApiResponse = unknown;
export type PostV1SignupEmailApiArg = {
  signupWithEmail: {
    email: EmailAddress;
  };
};
export type GetV1SignupTgApiResponse = unknown;
export type GetV1SignupTgApiArg = {
  domain: 'app.telespace.org' | 'app.telespace.market' | 'dev.telespace.org';
};
export type PostV1SignupTgApiResponse =
  /** status 200 Post telegram sign up response */ TelegramSignUpResponse;
export type PostV1SignupTgApiArg = {
  /** Post telegram sign up request */
  body: {
    domain?: string;
  };
};
export type PostV1ConfirmEmailApiResponse = /** status 200 Valid session */ Session;
export type PostV1ConfirmEmailApiArg = {
  emailConfirmation: {
    token: string;
  };
};
export type PostV1SigninEmailApiResponse = /** status 200 Valid session */ Session;
export type PostV1SigninEmailApiArg = {
  signInWithEmail: {
    email: EmailAddress;
    password: PasswordWrite;
  };
};
export type PostV1SigninTgApiResponse = /** status 200 Valid session */ Session;
export type PostV1SigninTgApiArg = {
  body: {
    token: string;
  };
};
export type GetV1UserApiResponse = /** status 200 User information */ User;
export type GetV1UserApiArg = void;
export type PatchV1UserApiResponse = /** status 200 User information */ User;
export type PatchV1UserApiArg = {
  userUpdate: UserWrite;
};
export type PostV1UserBalanceReplenishmentApiResponse = unknown;
export type PostV1UserBalanceReplenishmentApiArg = {
  /** Post request to replenish balance */
  body: {
    amount: string;
  };
};
export type PostV1UserBalanceWithdrawalApiResponse = unknown;
export type PostV1UserBalanceWithdrawalApiArg = {
  /** Post request to withdraw balance */
  body: {
    amount: string;
  };
};
export type PostV1UserOrganisationAddMemberApiResponse = unknown;
export type PostV1UserOrganisationAddMemberApiArg = {
  /** Post add member to organisation request */
  body: {
    newMember: string;
    OrganisationID: number;
  };
};
export type PostV1UserChannelApiResponse = unknown;
export type PostV1UserChannelApiArg = {
  body: {
    channel_id: Id;
  };
};
export type PatchV1UserChannelApiResponse = unknown;
export type PatchV1UserChannelApiArg = {
  /** Patch user channel request */
  body: {
    channel_id?: Id;
    exchange_info?: PartialChannelExchangeInfo;
    properties?: ChannelProperties;
  };
};
export type GetV1UserChannelsApiResponse = /** status 200 User channel list */ UserChannelsListRead;
export type GetV1UserChannelsApiArg = void;
export type GetV1UserChannelByChannelIdContactsApiResponse =
  /** status 200 list of channel admins */ ChannelAdminsList;
export type GetV1UserChannelByChannelIdContactsApiArg = {
  /** Channel ID */
  channelId: Id;
};
export type GetV1UserChannelFormatsApiResponse =
  /** status 200 exchange formats */ UserChannelExchangeFormat[];
export type GetV1UserChannelFormatsApiArg = {
  /** Formats ID */
  formatsId?: number[];
};
export type GetV2UserChannelFormatsApiResponse =
  /** status 200 exchange formats */ UserChannelExchangeFormatV2[];
export type GetV2UserChannelFormatsApiArg = {
  /** Formats ID */
  formatsId?: number[];
};
export type GetV1UserChannelFormatsParametersApiResponse =
  /** status 200 exchange formats parameters */ ChannelAvailableFormatsParameters;
export type GetV1UserChannelFormatsParametersApiArg = void;
export type PostV1UserChannelUpdateFormatApiResponse = unknown;
export type PostV1UserChannelUpdateFormatApiArg = {
  /** Update user channel format */
  body: UserChannelExchangeFormatV2 & {
    channel_id?: Id;
    prices?: ChannelExchangeFormatPrice[];
  };
};
export type PostV1UserChannelVerificationByChannelIdApiResponse = unknown;
export type PostV1UserChannelVerificationByChannelIdApiArg = {
  /** Channel ID */
  channelId: Id;
};
export type GetV1UserChannelCurrenciesApiResponse =
  /** status 200 user channel exchange currencies */ UserChannelExchangeCurrency[];
export type GetV1UserChannelCurrenciesApiArg = void;
export type PostV1UserCategoryPinApiResponse = unknown;
export type PostV1UserCategoryPinApiArg = {
  body: {
    category_id: number;
    pin: boolean;
  };
};
export type GetV1UserCategoryPinnedApiResponse =
  /** status 200 User category list */ CategoryListRead;
export type GetV1UserCategoryPinnedApiArg = void;
export type PostV1UserOrganisationApiResponse = /** status 201 organisation */ Organisation;
export type PostV1UserOrganisationApiArg = {
  /** Post organisation request */
  body: {
    owner_full_name: string;
    organisation_type: number;
    organisation_name: string;
    inn: string;
    ogrn: string;
    kpp: string;
    is_admin: boolean;
    is_advertiser: boolean;
  };
};
export type PatchV1UserOrganisationByOrganisationIdApiResponse =
  /** status 201 organisation */ Organisation;
export type PatchV1UserOrganisationByOrganisationIdApiArg = {
  organisationId: string;
  /** Patch organisation request */
  body: {
    owner_full_name?: string;
    is_admin?: boolean;
    is_advertiser?: boolean;
  };
};
export type GetV1UserOrganisationByOrganisationIdApiResponse =
  /** status 200 organisation */ Organisation;
export type GetV1UserOrganisationByOrganisationIdApiArg = {
  organisationId: string;
};
export type DeleteV1UserOrganisationByOrganisationIdApiResponse = unknown;
export type DeleteV1UserOrganisationByOrganisationIdApiArg = {
  organisationId: string;
};
export type GetV1OrganisationTypesApiResponse =
  /** status 200 Organisation types list */ OrganisationType[];
export type GetV1OrganisationTypesApiArg = void;
export type ChannelsApiResponse = /** status 200 Channels content */ ChannelsListResponseRead;
export type ChannelsApiArg = {
  /** Channels JWT encoded filter (see #/components/schemas/ChannelsFilter) */
  filter?: string;
};
export type GetV1ChannelsListApiResponse =
  /** status 200 list of channels */ ChannelsByIdsResponseRead;
export type GetV1ChannelsListApiArg = {
  /** ids of requested channels */
  ids: string[];
};
export type GetV1ChannelsFindApiResponse =
  /** status 200 Found channels content */ ChannelsFindResult;
export type GetV1ChannelsFindApiArg = {
  /** Channels search keywords */
  keywords: string;
};
export type GetV1ChannelsFilterFindApiResponse =
  /** status 200 Found channels content */ OwnAndOtherChannelsIdFindResult;
export type GetV1ChannelsFilterFindApiArg = {
  /** Channels search keywords */
  keyword: string;
};
export type PostV1ChannelsJoinApiResponse = /** status 200 Joined channel by admin */ JoinedChannel;
export type PostV1ChannelsJoinApiArg = {
  joinAdminBot: {
    username: string;
  };
};
export type PostV1ChannelsHistoricalMetricsApiResponse = unknown;
export type PostV1ChannelsHistoricalMetricsApiArg = {
  body: {
    channel_id: Id;
  };
};
export type GetV1ChannelsByChannelIdApiResponse =
  /** status 200 Channel content */ ChannelWithExchangeInfoRead;
export type GetV1ChannelsByChannelIdApiArg = {
  /** Channel ID */
  channelId: Id;
};
export type GetV1ChannelsByChannelIdInvitesApiResponse =
  /** status 200 only for admins */ GetChannelsInvitesResponse;
export type GetV1ChannelsByChannelIdInvitesApiArg = {
  /** Channel ID */
  channelId: Id;
};
export type GetV1ChannelsByChannelIdAndTypeTimeWindowApiResponse =
  /** status 200 Channel statistic content */ HistoryStatistic;
export type GetV1ChannelsByChannelIdAndTypeTimeWindowApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Expected data time zone in response */
  timeZone?: string;
  /** Type of channel metrics */
  type: MetricsType;
  timeWindow: TimeWindow;
};
export type GetV2ChannelsByMetricTypeApiResponse =
  /** status 200 Channel statistic content */ HistoryStatisticsForListOfChannels;
export type GetV2ChannelsByMetricTypeApiArg = {
  /** Expected data time zone in response */
  timeZone?: string;
  dateRange?: DateRange;
  /** ids of requested channels */
  ids: string[];
  /** Type of channel metrics */
  metricType: MetricsType;
  /** Status historical metrics loaded */
  historicalMetricsLoaded: boolean;
};
export type GetV1ChannelsByChannelIdParticipantsDetailsApiResponse =
  /** status 200 Channel statistic content */ ChannelParticipantsDetails;
export type GetV1ChannelsByChannelIdParticipantsDetailsApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Date range between two dates */
  dateRange: DateRange;
};
export type GetV1ChannelsByChannelIdAdvMentionsApiResponse =
  /** status 200 Channel adv posts statistic */ {
    channel_id: Id;
    last_date: string;
    total_count: number;
  }[];
export type GetV1ChannelsByChannelIdAdvMentionsApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Categories filter for posts */
  categories?: Id2[];
  sortBy?: 'date' | 'mentions' | 'name';
  order?: SortingOrder;
  advType: AdvertisingType;
};
export type GetV1ChannelsByChannelIdTimelineApiResponse =
  /** status 200 Channel timeline */ TimelinePostRead[];
export type GetV1ChannelsByChannelIdTimelineApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Pointer to the date */
  date?: string;
  direction?: 'up' | 'down';
  /** Limits response rows */
  limit?: number;
  /** Include reposts in timeline response */
  withReposts?: boolean;
};
export type GetV1ChannelsByChannelIdTimelineEventsApiResponse =
  /** status 200 Channel events timeline */ ChannelTimelineEventsRead;
export type GetV1ChannelsByChannelIdTimelineEventsApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Last event datetime */
  lastDate: string;
  /** Limits response rows */
  limit: number;
  /** Include reposts in timeline response */
  withReposts: boolean;
  /** Status historical metrics loaded */
  historicalMetricsLoaded: boolean;
  /** timeline direction */
  direction: Direction;
};
export type GetV2ChannelsByChannelIdTimelineEventsApiResponse =
  /** status 200 Channel events timeline */ MergedChannelTimelineEventsRead;
export type GetV2ChannelsByChannelIdTimelineEventsApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Last event datetime */
  lastDate: string;
  /** Limits response rows */
  limit: number;
  /** Include reposts in timeline response */
  withReposts: boolean;
  /** Status historical metrics loaded */
  historicalMetricsLoaded: boolean;
  /** timeline direction */
  direction: Direction;
};
export type GetV1ChannelsByChannelIdPostsApiResponse =
  /** status 200 All requested posts in channel */ ChannelPostRead[];
export type GetV1ChannelsByChannelIdPostsApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Posts JWT encoded navigation (see #/components/schemas/PostsNavigation) */
  navigation: string;
};
export type GetV1ChannelsByChannelIdPostsMentionsApiResponse =
  /** status 200 Channel advertising posts */ ChannelPostRead[];
export type GetV1ChannelsByChannelIdPostsMentionsApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Mentions JWT encoded navigation (see #/components/schemas/MentionsNavigation) */
  navigation: string;
};
export type GetV1ChannelsByChannelIdMentionsSourcesApiResponse =
  /** status 200 Channel mentions sources */ MentionSourcesRead[];
export type GetV1ChannelsByChannelIdMentionsSourcesApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Date range between two dates */
  dateRange: DateRange;
  /** Expected data time zone in response */
  timeZone?: string;
};
export type GetV1ChannelsByChannelIdDownloadStatusApiResponse =
  /** status 200 Get historical metrics download status */ DownloadStatus;
export type GetV1ChannelsByChannelIdDownloadStatusApiArg = {
  /** Channel ID */
  channelId: Id;
};
export type GetV1ChannelsByChannelIdPostsAndPostIdApiResponse =
  /** status 200 Channel post */ ChannelPostRead;
export type GetV1ChannelsByChannelIdPostsAndPostIdApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Post ID */
  postId: Id3;
};
export type GetV1ChannelsByChannelIdPostsAndPostIdTypeApiResponse =
  /** status 200 Post history statistic */ HistoryStatistic;
export type GetV1ChannelsByChannelIdPostsAndPostIdTypeApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Post ID */
  postId: Id3;
  /** Type of post metrics */
  type: PostMetricsType;
  /** Date range between two dates */
  dateRange: DateRange;
  /** Expected data time zone in response */
  timeZone?: string;
};
export type GetV1ChannelsByChannelIdPostsAndPostIdSubscribersApiResponse =
  /** status 200 Post mentions history statistic */ HistoryStatistic;
export type GetV1ChannelsByChannelIdPostsAndPostIdSubscribersApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Post ID */
  postId: Id3;
  /** Date range between two dates */
  dateRange: DateRange;
  /** Expected data time zone in response */
  timeZone?: string;
  /** Target channel ID */
  targetChannelId: Id;
};
export type GetV1ChannelsByChannelIdAdvApiResponse = /** status 200 Found adv posts */ {
  channel_id?: Id;
  post_id?: IdRead2;
  date: string;
  parallel_count: number;
  lifetime: number;
}[];
export type GetV1ChannelsByChannelIdAdvApiArg = {
  /** Channel ID */
  channelId: Id;
  /** Repost filter for posts */
  withRepost?: boolean;
  /** Parallel filter for posts */
  withParallel?: boolean;
  /** Categories filter for posts */
  categories?: Id2[];
  sortBy?: 'date' | 'views' | 'lifetime';
  order?: SortingOrder;
  advType: AdvertisingType;
};
export type PostV1InviteCreateApiResponse =
  /** status 200 link was created successfully */ CreateInviteLinkResponse;
export type PostV1InviteCreateApiArg = {
  createInviteLinkRequest: CreateInviteLinkRequest;
};
export type GetV1CategoriesApiResponse =
  /** status 200 A list of all known categories */ Category[];
export type GetV1CategoriesApiArg = void;
export type GetV1LanguagesApiResponse =
  /** status 200 A list of all known languages codes */ Category[];
export type GetV1LanguagesApiArg = void;
export type GetV1CountriesApiResponse =
  /** status 200 A list of all known countries codes */ Category[];
export type GetV1CountriesApiArg = void;
export type GetV1ExchangeFormatApiResponse = /** status 200 Found format list */ FormatAsResponse[];
export type GetV1ExchangeFormatApiArg = void;
export type PostV1ExchangeFormatApiResponse =
  /** status 200 Found format list */ FormatAsResponse[];
export type PostV1ExchangeFormatApiArg = {
  body: {
    format: Format;
  };
};
export type GetV1ExchangeCurrencyApiResponse =
  /** status 200 Found currency list */ CurrencyListResult;
export type GetV1ExchangeCurrencyApiArg = void;
export type GetV1ExchangeCurrencyByBaseCurrencyApiResponse =
  /** status 200 Found exchange rate */ ListOfExchangeRatesForBaseCurrency;
export type GetV1ExchangeCurrencyByBaseCurrencyApiArg = {
  baseCurrency: Currency;
};
export type GetV1ExchangePurchasesContainersApiResponse =
  /** status 200 All known containers list values */ PurchasesListContainer[];
export type GetV1ExchangePurchasesContainersApiArg = {
  /** Supported activity types of purchase */
  isActive?: boolean;
};
export type GetV1ExchangePurchasesContainersByContainerIdApiResponse =
  /** status 200 All known purchases list by container ID */ PurchaseRead[];
export type GetV1ExchangePurchasesContainersByContainerIdApiArg = {
  containerId: Id4;
};
export type PostV1ExchangePurchaseContainerApiResponse =
  /** status 200 Container ID */ ContainerIdAsResponse;
export type PostV1ExchangePurchaseContainerApiArg = {
  createContainer: {
    channel_id?: Id7;
    title?: string | null;
    budget?: number | null;
    currency?: Currency2;
    source_link?: string | null;
    type: ContainerType;
  };
};
export type PatchV1ExchangePurchaseContainerApiResponse =
  /** status 200 Entity container */ ContainerResponse;
export type PatchV1ExchangePurchaseContainerApiArg = {
  body: {
    container_id: string;
    title?: string | null;
    budget?: number | null;
    currency?: Currency2;
    note: Note;
    source_link?: string | null;
    type: ContainerType;
  };
};
export type PatchV1ExchangePurchaseContainerChannelIdApiResponse = unknown;
export type PatchV1ExchangePurchaseContainerChannelIdApiArg = {
  body: {
    container_id: Id4;
    channel_id: Id;
  };
};
export type PatchV1ExchangePurchaseContainerCloneApiResponse =
  /** status 200 Container ID list */ ContainerIdList;
export type PatchV1ExchangePurchaseContainerCloneApiArg = {
  containerIdList: ContainerIdList;
};
export type PatchV1ExchangePurchaseContainersChangeStatusApiResponse = unknown;
export type PatchV1ExchangePurchaseContainersChangeStatusApiArg = {
  changeActivityStatusForPurchaseLis: {
    container_id_list: Id4[];
    is_active: boolean;
  };
};
export type PostV1ExchangePurchaseContainersCreateApiResponse =
  /** status 200 Purchase Light List */ PurchaseLightList;
export type PostV1ExchangePurchaseContainersCreateApiArg = {
  createPurchase: {
    purchase_channel_id: Id;
    container_id_list: Id4[];
  };
};
export type PostV1ExchangePurchasesContainersCreateApiResponse =
  /** status 200 Purchase Light List */ PurchaseLightList;
export type PostV1ExchangePurchasesContainersCreateApiArg = {
  createPurchaseList: {
    purchase_channel_id_list: Id[];
    container_id_list: Id4[];
  };
};
export type PostV1ExchangePurchasesContainerNewCreateApiResponse =
  /** status 200 Purchase Light List */ PurchaseLightList;
export type PostV1ExchangePurchasesContainerNewCreateApiArg = {
  createPurchaseList: {
    purchase_channel_id_list: Id[];
    container_channel_id?: Id7;
    title?: string | null;
    budget?: number | null;
    currency?: Currency2;
    source_link?: string | null;
    container_type?: ContainerType;
  };
};
export type GetV1ExchangePurchaseByPurchaseIdApiResponse =
  /** status 200 Purchase content */ PurchaseRead;
export type GetV1ExchangePurchaseByPurchaseIdApiArg = {
  purchaseId: Id5;
};
export type PatchV1ExchangePurchaseUpdateApiResponse =
  /** status 200 Purchase update result */ PurchaseUpdateResultRead;
export type PatchV1ExchangePurchaseUpdateApiArg = {
  updatePurchase: {
    purchase_id: string;
    currency: Currency;
    format: Format;
    post_id: string | null;
    /** Time in minutes */
    time: number | null;
    note: string | null;
    is_paid: boolean;
    date: string | null;
    cpm_amount: number | null;
    cps_amount: number | null;
    cost: number | null;
    invite_link: string | null;
  };
};
export type PatchV1ExchangePurchaseStatusApiResponse =
  /** status 200 Purchase update result */ PurchaseUpdateResultRead;
export type PatchV1ExchangePurchaseStatusApiArg = {
  updatePurchaseStatus: {
    purchase_id: Id5;
    status: Status;
  };
};
export type PatchV1ExchangePurchaseDeleteApiResponse =
  /** status 200 Entity response message */ EntityCreatedMessage;
export type PatchV1ExchangePurchaseDeleteApiArg = {
  purchaseIdList: PurchaseIdList;
};
export type GetV1ExchangePurchaseCreativeByCreativeIdApiResponse =
  /** status 200 Found creative */ CreativeRead;
export type GetV1ExchangePurchaseCreativeByCreativeIdApiArg = {
  creativeId: Id8;
};
export type GetV1ExchangePurchaseByPurchaseIdCreativeApiResponse =
  /** status 200 Found creative */ CreativeRead;
export type GetV1ExchangePurchaseByPurchaseIdCreativeApiArg = {
  purchaseId: Id5;
};
export type PostV1ExchangeS3UploadFileApiResponse = /** status 200 Public s3 file url */ FileUrl;
export type PostV1ExchangeS3UploadFileApiArg = {
  body: {
    uploadFile: Blob;
  };
};
export type PostV1ExchangeS3UploadFilesApiResponse =
  /** status 200 Entity upload file result */ UploadResult;
export type PostV1ExchangeS3UploadFilesApiArg = {
  body: {
    uploadFiles: Blob[];
  };
};
export type PostV1ExchangeS3DeleteFilesApiResponse =
  /** status 200 Entity response message */ EntityCreatedMessage;
export type PostV1ExchangeS3DeleteFilesApiArg = {
  body: {
    delete_files: string[];
  };
};
export type GetV1ExchangeAuthorizedChannelsApiResponse =
  /** status 200 User authorized channel list */ AuthorizedChannelListRead;
export type GetV1ExchangeAuthorizedChannelsApiArg = void;
export type PostV1ExchangeAuthorizedChannelApiResponse =
  /** status 200 User authorized channel */ UserAuthorizedChannelRead;
export type PostV1ExchangeAuthorizedChannelApiArg = {
  authorizedChannel: UserAuthorizedChannel;
};
export type PatchV1ExchangeAuthorizedChannelApiResponse = unknown;
export type PatchV1ExchangeAuthorizedChannelApiArg = {
  authorizedChannel: UserAuthorizedChannel;
};
export type GetV1ExchangeMultipliersApiResponse =
  /** status 200 Exchange multipliers */ ExchangeMultipliers;
export type GetV1ExchangeMultipliersApiArg = void;
export type EmailAddress = string;
export type TelegramSignUpResponse = {
  url?: string;
};
export type Currency = string;
export type OrganisationIDs = {
  organisation_id: number;
  organisation_crm_id: number;
  role: string;
};
export type UserRole = 'admin' | 'advertiser';
export type User = {
  id: string;
  /** Pointer to the date */
  created_at: string;
  /** Pointer to the date */
  updated_at: string;
  username?: string;
  /** Path to avatar image. */
  avatar?: string;
  email?: EmailAddress;
  default_currency?: Currency;
  tg_username?: string;
  organisation_ids?: number[];
  crm_organisation_ids?: number[];
  organisations?: OrganisationIDs[];
  role?: UserRole;
};
export type SessionToken = string;
export type Session = {
  user: User;
  token: SessionToken;
};
export type Password = string;
export type PasswordWrite = string;
export type User2 = {
  username?: string;
  old_password?: Password;
  password?: Password;
  role?: UserRole;
};
export type UserWrite = {
  username?: string;
  old_password?: PasswordWrite;
  password?: PasswordWrite;
  role?: UserRole;
};
export type Id = string;
export type ChannelExchangeFormatPrice = {
  currency_id: number;
  price: string;
};
export type ChannelExchangeFormats = {
  format_id: number;
  is_active: boolean;
  prices?: ChannelExchangeFormatPrice[];
};
export type PartialChannelExchangeInfo = {
  channel_id: Id;
  contact_id?: string | null;
  is_exchange_active?: boolean | null;
  formats?: ChannelExchangeFormats[];
};
export type ChannelProperties = {
  language_id?: number | null;
  country_id?: number | null;
  category_id?: number | null;
};
export type Name = string;
export type NameRead = string;
export type Title = string;
export type Description = string;
export type Icon = string;
export type Channel = {
  id: Id;
  name: Name;
  title: Title;
  description?: Description;
  /** Pointer to the date */
  created_at: string;
  icon_url?: Icon;
  /** Rating is an internal channel rating. */
  rating?: number;
  /** Categories is the list of categories associated with channel. */
  categories?: number[];
  /** language of channel */
  language?: number;
  /** country of channel */
  country?: number;
  /** number of participants at moment of the last scan */
  participants?: number;
  /** does the channel have a bot? */
  has_bot: boolean;
  telegram_id?: number | null;
  /** average views of the channel */
  views: number;
  /** engagement rate of the channel */
  ER: number;
  /** engagement rate of the channel for last month */
  ERM: number;
  /** engagement rate of the channel for 24h */
  ERD: number;
  /** flag for unloading all historical metrics */
  historical_metrics_loaded: boolean;
  /** does the channel have an admin? */
  has_admin: boolean;
  link?: string;
};
export type ChannelRead = {
  id: Id;
  name: NameRead;
  title: Title;
  description?: Description;
  /** IsVerified is a channel marker of verification by Telegram. */
  is_verified?: boolean;
  /** IsPublic is a channel marker of channel visibility in Telegram. */
  is_public?: boolean;
  /** Pointer to the date */
  created_at: string;
  /** The date when channel was deleted. */
  deleted_at?: string | null;
  icon_url?: Icon;
  /** Rating is an internal channel rating. */
  rating?: number;
  /** Categories is the list of categories associated with channel. */
  categories?: number[];
  /** language of channel */
  language?: number;
  /** country of channel */
  country?: number;
  /** number of participants at moment of the last scan */
  participants?: number;
  /** does the channel have a bot? */
  has_bot: boolean;
  telegram_id?: number | null;
  /** average views of the channel */
  views: number;
  /** engagement rate of the channel */
  ER: number;
  /** engagement rate of the channel for last month */
  ERM: number;
  /** engagement rate of the channel for 24h */
  ERD: number;
  /** flag for unloading all historical metrics */
  historical_metrics_loaded: boolean;
  /** does the channel have an admin? */
  has_admin: boolean;
  link?: string;
};
export type ChannelExchangeInfo = {
  channel_id: Id;
  contact_id?: string;
  contact_username?: string;
  is_exchange_active: boolean;
  verification_status?: 'not_verified' | 'in_progress' | 'verified' | 'not_started';
  formats?: ChannelExchangeFormats[];
};
export type ChannelWithExchangeInfo = {
  info: Channel;
  exchange_info?: ChannelExchangeInfo;
};
export type ChannelWithExchangeInfoRead = {
  info: ChannelRead;
  exchange_info?: ChannelExchangeInfo;
};
export type UserChannelsList = ChannelWithExchangeInfo[];
export type UserChannelsListRead = ChannelWithExchangeInfoRead[];
export type ChannelAdmin = {
  user_id: string;
  telegram_username: string;
};
export type ChannelAdminsList = ChannelAdmin[];
export type UserChannelExchangeFormat = {
  format_id: number;
  name: string;
  time_in_top_hours: number;
  time_in_adv_hours: number;
};
export type UserChannelExchangeFormatV2 = {
  format_id: number;
  time_in_top_minutes: number;
  time_in_top_name: string;
  time_in_feed_minutes: number;
  time_in_feed_name: string;
};
export type FormatParameter = {
  time: number;
  name: string;
};
export type ChannelAvailableFormatsParameters = {
  time_in_top_minutes?: FormatParameter[];
  time_in_feed_minutes?: FormatParameter[];
};
export type UserChannelExchangeCurrency = {
  id: number;
  name: string;
};
export type Id2 = number;
export type IdRead = number;
export type CategoryList = {
  category_list?: Id2[];
};
export type CategoryListRead = {
  category_list?: IdRead[];
};
export type Organisation = {
  organisation_id: number;
  organisation_name: string;
  owner_full_name: string;
  organisation_type: number;
  inn: string;
  ogrn: string;
  kpp: string;
  is_admin: boolean;
  is_advertiser: boolean;
};
export type OrganisationType = {
  id: number;
  name: string;
};
export type ChannelsWithExchangeInfo = ChannelWithExchangeInfo[];
export type ChannelsWithExchangeInfoRead = ChannelWithExchangeInfoRead[];
export type ChannelsListResponse = {
  channels?: ChannelsWithExchangeInfo;
  /** Total found channels */
  total: number;
};
export type ChannelsListResponseRead = {
  channels?: ChannelsWithExchangeInfoRead;
  /** Total found channels */
  total: number;
};
export type Channels = Channel[];
export type ChannelsRead = ChannelRead[];
export type ChannelsByIdsResponse = {
  channels?: Channels;
};
export type ChannelsByIdsResponseRead = {
  channels?: ChannelsRead;
};
export type ChannelsFindResult = {
  channels?: Id[];
  descriptions?: Id[];
};
export type OwnAndOtherChannelsIdFindResult = {
  own_ids?: Id[];
  other_ids_title?: Id[];
  other_ids_description?: Id[];
};
export type JoinedChannel = {
  channel_id: Id;
};
export type InviteLink = {
  link: string;
  name?: string;
  user_limit?: number;
  time_limit?: string;
};
export type GetChannelsInvitesResponse = {
  channel_id: Id;
  invite_links?: InviteLink[];
};
export type HistoryArray = number[];
export type Accuracy = 'day' | 'hour';
export type HistoryStatistic = {
  history?: HistoryArray;
  accuracy?: Accuracy;
  /** Pointer to the date */
  from: string;
  /** Pointer to the date */
  to: string;
};
export type MetricsType =
  | 'participants'
  | 'views'
  | 'placements'
  | 'mentions'
  | 'participantsDiffs'
  | 'reaches'
  | 'avg-daily-err'
  | 'avg-daily-reaches';
export type TimeWindow = 'today' | 'yesterday' | 'week' | 'month' | 'all';
export type HistoryStatistic2 = {
  channel_id: string;
  history: HistoryArray;
  accuracy?: Accuracy;
  /** Pointer to the date */
  from: string;
  /** Pointer to the date */
  to: string;
};
export type HistoryStatisticsForListOfChannels = {
  channels?: HistoryStatistic2[];
};
export type DateRange = {
  /** Pointer to the date */
  from?: string;
  /** Pointer to the date */
  to: string;
};
export type ChannelParticipantsDetails = {
  subscribes?: HistoryArray;
  unsubscribes?: HistoryArray;
  quick_unsubscribes?: HistoryArray;
  accuracy?: Accuracy;
  /** Pointer to the date */
  from: string;
  /** Pointer to the date */
  to: string;
};
export type SortingOrder = 'ASC' | 'DESC';
export type AdvertisingType = 'mentions' | 'placements';
export type Id3 = number;
export type IdRead2 = number;
export type PostType = 'regular' | 'repost' | 'mention';
export type TimelinePost = {
  channel_id: Id;
  post_id: Id3;
  /** Pointer to the date */
  created_at: string;
  type: PostType;
};
export type TimelinePostRead = {
  channel_id: Id;
  post_id: IdRead2;
  /** Pointer to the date */
  created_at: string;
  /** The date when post was deleted. */
  deleted_at: string | null;
  type: PostType;
};
export type TimelineEventsPost = {
  channel_id: Id;
  post_id: Id3;
  /** Pointer to the date */
  date: string;
  type: PostType;
  /** Channel name */
  channel_name: string;
  /** Views */
  views: number;
};
export type TimelineEventsPostRead = {
  channel_id: Id;
  post_id: IdRead2;
  /** Pointer to the date */
  date: string;
  type: PostType;
  /** Channel name */
  channel_name: string;
  /** Views */
  views: number;
};
export type ParticipantsDelta = {
  /** Date */
  date: string;
  /** Delta */
  delta: number;
};
export type ChannelTimelineEvents = {
  posts: TimelineEventsPost[];
  participants_delta: ParticipantsDelta[];
};
export type ChannelTimelineEventsRead = {
  posts: TimelineEventsPostRead[];
  participants_delta: ParticipantsDelta[];
};
export type Direction = 'up' | 'down';
export type MergedChannelTimelineEvents = {
  events: (TimelineEventsPost | ParticipantsDelta)[];
};
export type MergedChannelTimelineEventsRead = {
  events: (TimelineEventsPostRead | ParticipantsDelta)[];
};
export type ChannelPost = {
  channel_id?: Id;
  post_id?: Id3;
  content: string;
  type: PostType;
};
export type ChannelPostRead = {
  channel_id?: Id;
  post_id?: IdRead2;
  content: string;
  /** The date of post is posted. */
  posted_at: string;
  /** The date when post was deleted. */
  deleted_at?: string | null;
  type: PostType;
  /** Counter of total post views. */
  views?: number;
  /** Counter of total post shares. */
  shares?: number;
  /** Counter of subscribes after post was published. */
  subscribes?: number;
  /** Counter of total reposts in other channels. */
  reposts?: number;
  /** Flag if post is a collection of mentions. */
  collection?: boolean;
  /** Lifetime in seconds while this post has no parallel adv. */
  lifetime?: number;
};
export type TimeWindowResponse = {
  accuracy?: Accuracy;
  start?: string;
  end?: string;
};
export type MentionSource = {
  channel_id?: Id;
  post_id?: Id3;
  subscribes?: number;
};
export type MentionSourceRead = {
  channel_id?: Id;
  post_id?: IdRead2;
  subscribes?: number;
};
export type MentionSources = {
  window: TimeWindowResponse;
  days: MentionSource[][];
};
export type MentionSourcesRead = {
  window: TimeWindowResponse;
  days: MentionSourceRead[][];
};
export type DownloadStatus = 'in_progress' | 'fail' | 'not_found' | 'received' | 'ready';
export type PostMetricsType = 'subscribes' | 'views';
export type CreateInviteLinkResponse = {
  channel_id?: Id;
  link?: string;
  name?: string;
};
export type CreateInviteLinkRequest = {
  channel_id: Id;
  link_name?: string;
  approved_required?: boolean;
  user_limit?: number;
  time_limit?: string;
};
export type Category = {
  /** An unique category identifier within the project */
  id: number;
  /** An unique code name of category */
  code_name: string;
};
export type Format = {
  time_in_top_hours: number;
  time_in_ad_hours: number;
};
export type FormatAsResponse = {
  format_id?: string;
  format?: Format;
};
export type CurrencyListResult = {
  currency_list?: Currency[];
};
export type ExchangeRate = {
  currency: Currency;
  rate?: number;
};
export type ListOfExchangeRatesForBaseCurrency = ExchangeRate[];
export type Id4 = string;
export type ChannelIdExtension = {
  id?: Id;
  channel_title?: Title;
  icon?: Icon;
  categories?: number[];
};
export type Note = string;
export type PostOrCreativeNumber = number;
export type Amount = number;
export type TotalPurchase = {
  /** Pointer to the date */
  date?: string;
  cpm_amount?: Amount;
  cps_amount?: Amount;
  cost?: Amount;
  /** Coverage is ad views */
  coverage?: number;
  /** Subscribers from ad */
  subscribers?: number;
};
export type ContainerType = string | null;
export type PurchasesListContainer = {
  container_id: Id4;
  /** Title is a short purchases list description. */
  title?: string | null;
  channel?: ChannelIdExtension;
  budget: number;
  currency: Currency;
  note?: Note;
  number?: PostOrCreativeNumber;
  format?: Format;
  common_purchase_data?: TotalPurchase;
  is_active: boolean;
  type: ContainerType;
  source_link?: string | null;
};
export type Id5 = string;
export type Id6 = string | null;
export type IdRead3 = string | null;
export type Status =
  | 'planned'
  | 'published'
  | 'deleted'
  | 'broken'
  | 'pending'
  | 'declined'
  | 'post_requested'
  | 'cancelled'
  | 'unpublished';
export type Purchase = {
  channel: ChannelWithExchangeInfo;
  purchase_id: Id5;
  note?: Note;
  post_id: Id6;
  telegram_message_id?: number | null;
  currency: Currency;
  format: Format;
  status?: Status;
  /** Time in minutes */
  time: number;
  is_paid: boolean;
  common_purchase_data?: TotalPurchase;
  invite_link: string;
  published_at?: string | null;
};
export type PurchaseRead = {
  channel: ChannelWithExchangeInfoRead;
  purchase_id: Id5;
  note?: Note;
  post_id: IdRead3;
  telegram_message_id?: number | null;
  currency: Currency;
  format: Format;
  status?: Status;
  /** Time in minutes */
  time: number;
  is_paid: boolean;
  common_purchase_data?: TotalPurchase;
  invite_link: string;
  published_at?: string | null;
};
export type ContainerIdAsResponse = {
  container_id?: Id4;
};
export type Id7 = string | null;
export type Currency2 = string | null;
export type ContainerResponse = {
  container_id: string;
  channel_id: Id;
  title?: string | null;
  budget: number;
  currency: Currency;
  note: Note;
  type: ContainerType;
  source_link?: string | null;
};
export type ContainerIdList = {
  id_list: Id4[];
};
export type PurchaseLight = {
  containerId?: Id4;
  purchaseId?: Id5;
  channelId?: Id;
};
export type PurchaseLightList = PurchaseLight[];
export type PurchaseUpdateResult = {
  container_id?: Id4;
  purchase?: Purchase;
};
export type PurchaseUpdateResultRead = {
  container_id?: Id4;
  purchase?: PurchaseRead;
};
export type EntityCreatedMessage = {
  msg: string;
};
export type PurchaseIdList = {
  id_list: Id5[];
};
export type Id8 = string;
export type IdRead4 = string;
export type Creative = {
  id: Id8;
  purchase_id: Id5;
  text: string;
  created_at: string;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type CreativeRead = {
  id: IdRead4;
  purchase_id: Id5;
  text: string;
  created_at: string;
  updated_at?: string | null;
  deleted_at?: string | null;
};
export type FileUrl = {
  file_url: string;
};
export type UploadResult = {
  [key: string]: string;
};
export type FormatCostCurrency = {
  format: Format;
  format_enabled: boolean;
  cost: number;
  currency: Currency;
  ton_enabled: boolean;
};
export type DayPlacing = 'MON' | 'TUE' | 'WED' | 'THU' | 'FRI' | 'SAT' | 'SUN';
export type TimePlacing = {
  /** Time in minutes */
  from: number;
  /** Time in minutes */
  to: number;
  time_placing_enabled: boolean;
};
export type UserAuthorizedChannel = {
  id: number | null;
  channel_id: Id;
  channel_theme: string;
  channel_admin: string;
  country: string;
  lang: string;
  exchange_enabled: boolean;
  show_additional_statistic: boolean;
  format_cost_currencies: FormatCostCurrency[];
  day_placings: DayPlacing[];
  time_placings: TimePlacing[];
};
export type UserAuthorizedChannelRead = {
  id: number | null;
  channel_id: Id;
  channel_theme: string;
  channel_admin: string;
  country: string;
  lang: string;
  exchange_enabled: boolean;
  show_additional_statistic: boolean;
  format_cost_currencies: FormatCostCurrency[];
  day_placings: DayPlacing[];
  time_placings: TimePlacing[];
  channel_title?: string;
  channel_icon?: string;
};
export type AuthorizedChannelList = {
  channel_list?: UserAuthorizedChannel[];
};
export type AuthorizedChannelListRead = {
  channel_list?: UserAuthorizedChannelRead[];
};
export type ExchangeMultipliers = {
  admin_multiplier?: number;
  client_multiplier?: number;
};
export const {
  usePostV1SignupEmailMutation,
  useGetV1SignupTgQuery,
  useLazyGetV1SignupTgQuery,
  usePostV1SignupTgMutation,
  usePostV1ConfirmEmailMutation,
  usePostV1SigninEmailMutation,
  usePostV1SigninTgMutation,
  useGetV1UserQuery,
  useLazyGetV1UserQuery,
  usePatchV1UserMutation,
  usePostV1UserBalanceReplenishmentMutation,
  usePostV1UserBalanceWithdrawalMutation,
  usePostV1UserOrganisationAddMemberMutation,
  usePostV1UserChannelMutation,
  usePatchV1UserChannelMutation,
  useGetV1UserChannelsQuery,
  useLazyGetV1UserChannelsQuery,
  useGetV1UserChannelByChannelIdContactsQuery,
  useLazyGetV1UserChannelByChannelIdContactsQuery,
  useGetV1UserChannelFormatsQuery,
  useLazyGetV1UserChannelFormatsQuery,
  useGetV2UserChannelFormatsQuery,
  useLazyGetV2UserChannelFormatsQuery,
  useGetV1UserChannelFormatsParametersQuery,
  useLazyGetV1UserChannelFormatsParametersQuery,
  usePostV1UserChannelUpdateFormatMutation,
  usePostV1UserChannelVerificationByChannelIdMutation,
  useGetV1UserChannelCurrenciesQuery,
  useLazyGetV1UserChannelCurrenciesQuery,
  usePostV1UserCategoryPinMutation,
  useGetV1UserCategoryPinnedQuery,
  useLazyGetV1UserCategoryPinnedQuery,
  usePostV1UserOrganisationMutation,
  usePatchV1UserOrganisationByOrganisationIdMutation,
  useGetV1UserOrganisationByOrganisationIdQuery,
  useLazyGetV1UserOrganisationByOrganisationIdQuery,
  useDeleteV1UserOrganisationByOrganisationIdMutation,
  useGetV1OrganisationTypesQuery,
  useLazyGetV1OrganisationTypesQuery,
  useChannelsQuery,
  useLazyChannelsQuery,
  useGetV1ChannelsListQuery,
  useLazyGetV1ChannelsListQuery,
  useGetV1ChannelsFindQuery,
  useLazyGetV1ChannelsFindQuery,
  useGetV1ChannelsFilterFindQuery,
  useLazyGetV1ChannelsFilterFindQuery,
  usePostV1ChannelsJoinMutation,
  usePostV1ChannelsHistoricalMetricsMutation,
  useGetV1ChannelsByChannelIdQuery,
  useLazyGetV1ChannelsByChannelIdQuery,
  useGetV1ChannelsByChannelIdInvitesQuery,
  useLazyGetV1ChannelsByChannelIdInvitesQuery,
  useGetV1ChannelsByChannelIdAndTypeTimeWindowQuery,
  useLazyGetV1ChannelsByChannelIdAndTypeTimeWindowQuery,
  useGetV2ChannelsByMetricTypeQuery,
  useLazyGetV2ChannelsByMetricTypeQuery,
  useGetV1ChannelsByChannelIdParticipantsDetailsQuery,
  useLazyGetV1ChannelsByChannelIdParticipantsDetailsQuery,
  useGetV1ChannelsByChannelIdAdvMentionsQuery,
  useLazyGetV1ChannelsByChannelIdAdvMentionsQuery,
  useGetV1ChannelsByChannelIdTimelineQuery,
  useLazyGetV1ChannelsByChannelIdTimelineQuery,
  useGetV1ChannelsByChannelIdTimelineEventsQuery,
  useLazyGetV1ChannelsByChannelIdTimelineEventsQuery,
  useGetV2ChannelsByChannelIdTimelineEventsQuery,
  useLazyGetV2ChannelsByChannelIdTimelineEventsQuery,
  useGetV1ChannelsByChannelIdPostsQuery,
  useLazyGetV1ChannelsByChannelIdPostsQuery,
  useGetV1ChannelsByChannelIdPostsMentionsQuery,
  useLazyGetV1ChannelsByChannelIdPostsMentionsQuery,
  useGetV1ChannelsByChannelIdMentionsSourcesQuery,
  useLazyGetV1ChannelsByChannelIdMentionsSourcesQuery,
  useGetV1ChannelsByChannelIdDownloadStatusQuery,
  useLazyGetV1ChannelsByChannelIdDownloadStatusQuery,
  useGetV1ChannelsByChannelIdPostsAndPostIdQuery,
  useLazyGetV1ChannelsByChannelIdPostsAndPostIdQuery,
  useGetV1ChannelsByChannelIdPostsAndPostIdTypeQuery,
  useLazyGetV1ChannelsByChannelIdPostsAndPostIdTypeQuery,
  useGetV1ChannelsByChannelIdPostsAndPostIdSubscribersQuery,
  useLazyGetV1ChannelsByChannelIdPostsAndPostIdSubscribersQuery,
  useGetV1ChannelsByChannelIdAdvQuery,
  useLazyGetV1ChannelsByChannelIdAdvQuery,
  usePostV1InviteCreateMutation,
  useGetV1CategoriesQuery,
  useLazyGetV1CategoriesQuery,
  useGetV1LanguagesQuery,
  useLazyGetV1LanguagesQuery,
  useGetV1CountriesQuery,
  useLazyGetV1CountriesQuery,
  useGetV1ExchangeFormatQuery,
  useLazyGetV1ExchangeFormatQuery,
  usePostV1ExchangeFormatMutation,
  useGetV1ExchangeCurrencyQuery,
  useLazyGetV1ExchangeCurrencyQuery,
  useGetV1ExchangeCurrencyByBaseCurrencyQuery,
  useLazyGetV1ExchangeCurrencyByBaseCurrencyQuery,
  useGetV1ExchangePurchasesContainersQuery,
  useLazyGetV1ExchangePurchasesContainersQuery,
  useGetV1ExchangePurchasesContainersByContainerIdQuery,
  useLazyGetV1ExchangePurchasesContainersByContainerIdQuery,
  usePostV1ExchangePurchaseContainerMutation,
  usePatchV1ExchangePurchaseContainerMutation,
  usePatchV1ExchangePurchaseContainerChannelIdMutation,
  usePatchV1ExchangePurchaseContainerCloneMutation,
  usePatchV1ExchangePurchaseContainersChangeStatusMutation,
  usePostV1ExchangePurchaseContainersCreateMutation,
  usePostV1ExchangePurchasesContainersCreateMutation,
  usePostV1ExchangePurchasesContainerNewCreateMutation,
  useGetV1ExchangePurchaseByPurchaseIdQuery,
  useLazyGetV1ExchangePurchaseByPurchaseIdQuery,
  usePatchV1ExchangePurchaseUpdateMutation,
  usePatchV1ExchangePurchaseStatusMutation,
  usePatchV1ExchangePurchaseDeleteMutation,
  useGetV1ExchangePurchaseCreativeByCreativeIdQuery,
  useLazyGetV1ExchangePurchaseCreativeByCreativeIdQuery,
  useGetV1ExchangePurchaseByPurchaseIdCreativeQuery,
  useLazyGetV1ExchangePurchaseByPurchaseIdCreativeQuery,
  usePostV1ExchangeS3UploadFileMutation,
  usePostV1ExchangeS3UploadFilesMutation,
  usePostV1ExchangeS3DeleteFilesMutation,
  useGetV1ExchangeAuthorizedChannelsQuery,
  useLazyGetV1ExchangeAuthorizedChannelsQuery,
  usePostV1ExchangeAuthorizedChannelMutation,
  usePatchV1ExchangeAuthorizedChannelMutation,
  useGetV1ExchangeMultipliersQuery,
  useLazyGetV1ExchangeMultipliersQuery
} = injectedRtkApi;
