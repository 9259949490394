import { ComponentProps } from 'react';

import * as S from './styles';

type Props = ComponentProps<typeof S.Input>;

const Input = forwardRef<HTMLInputElement, Props>(({ value, placeholder, ...restProps }, ref) => {
  return (
    <S.Root>
      <S.Input ref={ref} value={value} {...restProps} />
      <S.Pseudo>{value || placeholder}</S.Pseudo>
    </S.Root>
  );
});

export default memo(Input);
