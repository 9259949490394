import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { flattenObj, isEqual } from 'fsd/shared/lib/helpers/tools';
import { useStickyState } from 'fsd/shared/lib/hooks/useStickyState';
import { FilterState } from 'types/channels/types';

import { useAuth } from '../../../../../fsd/app/providers/Auth';
import { CatalogProps, initialValues } from '../../..';

import { useFiltersCount } from './useFiltersCount';

export const useCatalogLogic = ({ catalogProps }: CatalogProps) => {
  const [isFiltersModalVisible, setFiltersVisible] = useState(false);
  const [formSettings] = useStickyState(initialValues, 'formSettings', true);
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSubmit = useCallback(
    (values: any) => {
      setSearchParams(flattenObj(values));
      setFiltersVisible(false);
      catalogProps.handleSubmit(values);
    },
    [catalogProps, setSearchParams]
  );

  const navigate = useNavigate();

  const handleChannelSearchItemClick = useCallback(
    (channelId: string) => {
      navigate(`/channel/${channelId}`);
    },
    [navigate]
  );

  const { isAuth } = useAuth();

  const { form, values, data, isFetching } = catalogProps;
  const { filtersCount } = useFiltersCount({ catalogProps });

  // form.change('is_exchange_active', false);

  const resetFilters = useCallback(() => {
    setSearchParams(undefined);
    const { categories } = values;

    // reset filters
    // if we use form.reset() - we will get submit filters before reset. it's strange. so we use form.change() for each field
    form.change('participants', undefined);
    // @ts-ignore
    form.change('avg-daily-reaches', undefined);
    form.change('avg_daily_reaches', undefined);
    form.change('cpm', undefined);
    // @ts-ignore
    form.change('format_ids', undefined);
    form.change('mentions', undefined);
    form.change('er', undefined);
    form.change('is_verified', undefined);
    form.change('prices', undefined);
    form.change('is_public', undefined);
    form.change('languages', []);

    form.change('categories', categories);
  }, [form, setSearchParams, values]);

  useEffect(() => {
    if (!isEqual(values, formSettings)) {
      setSearchParams(flattenObj({ ...values }));
      return;
    }

    const categoriesParams: number[] = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const parametr of searchParams) {
      const format_ids = new Set<number>();
      if (parametr[0] === 'categories') {
        categoriesParams.push(Number(parametr[1]));
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        form.change('categories', categoriesParams as any);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } else {
        form.change(parametr[0] as keyof FilterState, parametr[1] as any);
      }

      if (parametr[0] === 'format_ids') {
        format_ids.add(Number(parametr[1]));
      }
      // @ts-ignore
      form.change('format_ids', Array.from(format_ids));
      if (
        parametr[0] === 'is_public' ||
        parametr[0] === 'is_verified' ||
        parametr[0] === 'is_exchange_active'
      ) {
        // fix for is_public and is_verified.
        // It's boolean in form, but string in url, so we need to convert it
        if (parametr[1] === 'true') {
          form.change(parametr[0], true);
        }
        if (parametr[1] === 'false') {
          form.change(parametr[0], false);
        }
      }
    }
    form.submit()?.then((value) => {
      setSearchParams(flattenObj({ ...value }));
    });
  }, [window.location]);

  return {
    isFiltersModalVisible,
    setFiltersVisible,
    formSettings,
    handleSubmit,
    resetFilters,
    navigate,
    handleChannelSearchItemClick,
    isAuth,
    searchParams,
    setSearchParams,
    form,
    values,
    data,
    isFetching,
    filtersCount
  };
};
