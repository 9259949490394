import { FC } from 'react';
import { PurchaseRead, usePatchV1ExchangePurchaseStatusMutation } from 'api/api';
import { useCheckBalanceToSendPurchase } from 'fsd/entities/purchase';
import { useLazyGetUserOrganisation } from 'fsd/entities/user';
import { CreateOrganisationModal } from 'fsd/features/createOrganisation';
import { RechargeBalanceToSendPurchaseModal } from 'fsd/features/rechargeBalanceToSendPurchase/cross';
import { URLs } from 'fsd/shared/config/URLs';

import DateModal from '../DateModal';

import * as S from './styles';
import CustomToast from 'fsd/shared/ui/Toast/customToast';
import { ToastMessage } from 'fsd/shared/ui/Toast';

type Props = {
  containerId: string;
  purchase: PurchaseRead;
};

const Button: FC<Props> = ({ purchase, containerId }) => {
  const [getUserOrganisation] = useLazyGetUserOrganisation();
  const [createOrganisationOpen, setCreateOrganisationOpen] = useState(false);
  const [rechargeOpen, setRechargeOpen] = useState(false);
  const [updatePurchaseStatus, { isLoading }] = usePatchV1ExchangePurchaseStatusMutation();
  const isAllowBalance = useCheckBalanceToSendPurchase(purchase);
  const [dateOpen, setDateOpen] = useState(false);
  const hasDateAndTime = Boolean(
    typeof purchase.time === 'number' && purchase.common_purchase_data?.date
  );
  const isWaitingCreative = purchase.status === 'post_requested';

  const handleDateOpenChange = useCallback((open: boolean) => {
    setDateOpen(open);
  }, []);

  const send = useCallback(() => {
    if (purchase.status !== 'pending_await') {
      CustomToast({
        type: 'error',
        message: <ToastMessage title="Добавьте креатив" />,
        options: {
          autoClose: 1000
        }
      });
      return;
    }
    if (isAllowBalance) {
      updatePurchaseStatus({
        updatePurchaseStatus: {
          purchase_id: purchase.purchase_id,
          status: 'pending'
        }
      });
    } else {
      setRechargeOpen(true);
    }
  }, [isAllowBalance, purchase.purchase_id, purchase.status, updatePurchaseStatus]);

  const handleRechargeOpenChange = useCallback((open: boolean) => {
    setRechargeOpen(open);
  }, []);

  const callSendPost = useCallback(() => {
    if (!hasDateAndTime) {
      setDateOpen(true);
    } else {
      send();
    }
  }, [hasDateAndTime, send]);

  const handleClick = useCallback(() => {
    getUserOrganisation().then((res) => {
      if (res?.data?.data) {
        callSendPost();
      } else {
        setCreateOrganisationOpen(true);
      }
    });
  }, [callSendPost, getUserOrganisation]);

  const handleCreateOrganisationClose = useCallback(() => {
    setCreateOrganisationOpen(false);
  }, []);

  const handleCreateOrganisationSuccess = useCallback(() => {
    setCreateOrganisationOpen(false);
    callSendPost();
  }, [callSendPost]);

  return (
    <>
      <CreateOrganisationModal
        open={createOrganisationOpen}
        onClose={handleCreateOrganisationClose}
        onSuccess={handleCreateOrganisationSuccess}
      />
      <RechargeBalanceToSendPurchaseModal
        containerId={containerId}
        purchase={purchase}
        open={rechargeOpen}
        onOpenChange={handleRechargeOpenChange}
      />
      {!hasDateAndTime && (
        <DateModal
          purchase={purchase}
          open={dateOpen}
          onOpenChange={handleDateOpenChange}
          onSuccess={send}
        />
      )}
      {isWaitingCreative ? (
        <S.Root disabled>Ожидаем креатив</S.Root>
      ) : (
        <S.Root onClick={handleClick} disabled={isLoading}>
          Отправить заявку
        </S.Root>
      )}
    </>
  );
};

export default memo(Button);
