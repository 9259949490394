import { ComponentProps, FC } from 'react';
import { Purchase, usePatchV1ExchangePurchaseUpdateMutation } from 'api/api';
import { isPurchaseAllowPay } from 'fsd/entities/purchase';
import { IconWalletFill } from 'fsd/shared/icons';
import { Button as SharedButton } from 'fsd/shared/ui/Buttons';
import { mergeDeep } from 'remeda';

import createSuccessToastMessage from '../model/createSuccessToastMessage';

type Props = ComponentProps<typeof SharedButton> & { purchase: Purchase };

const Button: FC<Props> = ({ children, purchase, ...restProps }) => {
  const [updatePurchase, { isLoading }] = usePatchV1ExchangePurchaseUpdateMutation();

  const handleClick = useCallback(() => {
    updatePurchase({
      updatePurchase: {
        ...mergeDeep(purchase, {
          common_purchase_data: {
            date: null
          }
        }),
        note: purchase.note ?? null,
        cpm_amount: purchase.common_purchase_data?.cpm_amount ?? null,
        cps_amount: purchase.common_purchase_data?.cps_amount ?? null,
        cost: purchase.common_purchase_data?.cost ?? null,
        is_paid: true,
        time: null,
        date: null
      }
    })
      .unwrap()
      .then(() => {
        createSuccessToastMessage();
      });
  }, [updatePurchase, purchase]);

  return (
    <SharedButton
      {...restProps}
      disabled={isLoading || !isPurchaseAllowPay(purchase)}
      type="primary"
      startIcon={<IconWalletFill />}
      onClick={handleClick}
    >
      {children || 'Оплатить'}
    </SharedButton>
  );
};

export default memo(Button);
