/* eslint-disable no-unused-vars */
import { FC } from 'react';
import { UserRole } from 'api/api';
import { IconAccount, IconBusiness } from 'fsd/shared/icons';
import { Icon } from 'fsd/shared/ui/Icon';

import * as S from './styles';

type Props = {
  value?: UserRole;
  onChange: (value: UserRole) => void;
};

const RoleSelect: FC<Props> = ({ value, onChange }) => {
  const handleClick = useCallback(
    (value: UserRole) => {
      return () => onChange(value);
    },
    [onChange]
  );

  return (
    <S.Root>
      <S.Option
        $active={value === 'advertiser'}
        icon={
          <Icon width={20}>
            <IconBusiness />
          </Icon>
        }
        onClick={handleClick('advertiser')}
      >
        Я заказчик
      </S.Option>
      <S.Option
        $active={value === 'admin'}
        icon={
          <Icon width={20}>
            <IconAccount />
          </Icon>
        }
        onClick={handleClick('admin')}
      >
        Я владелец канала
      </S.Option>
    </S.Root>
  );
};

export default memo(RoleSelect);
