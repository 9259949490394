import { MEDIA_DEVICE } from 'fsd/shared/config/mediaDevice';
import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  --padding-x: 24px;
  background: #fff;
  width: 100%;
  height: 100%;
  position: relative;
  bottom: 0;
  top: 0;
  left: auto;
  @media ${MEDIA_DEVICE.mobileL.to} {
    --padding-x: 16px;
  }
`;

export const Body = styled.div`
  flex: 1;
  display: flex;
  padding: 24px var(--padding-x) 0;
  flex-direction: column;
  overflow-y: auto;
`;

export const Footer = styled.div`
  display: flex;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.alpineGoat};
  margin-top: auto;
  gap: 16px;
  padding: 16px 24px 24px 24px;
`;
